import { Box, IconButton, Modal } from "@mui/material";
import SubscribeMessage from "./SubscribeMessage";
import "../css/SubscribeForm.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// use the render prop and your custom form
export default function SubscribeForm ({openSubscribe,setOpenSubscribe}) {
  return <Modal 
  open={openSubscribe}
  onClose={() => setOpenSubscribe(false)}
  sx={{
    display:"flex",
    justifyContent:"center", 
    alignItems:"center"}}
  disableAutoFocus={true}
  >
    <Box sx={{
      width:"60%", 
      minWidth:"416px",
      height:"fit-content",
      position:"relative",
      display:"flex",
      alignItems:"center"
      }}>
      <IconButton
            aria-label="close modal"
            onClick={() => setOpenSubscribe(false)}
            sx={{ 
              position: "absolute", 
              top: 5, 
              right: 5,
              height:"30px", 
              ":hover":{
                backgroundColor:"#dad8d6",
              }
             }}
          >
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
      <SubscribeMessage />
    </Box>
  </Modal>
}