import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logo from "../../assets/OxProx-Logo-White-and-Red.png";
import "../css/Navmenu.scss";
import { includesUserRoles } from "../../utils/user";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  faBars,
  faDoorClosed,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import useTheme from "@mui/material/styles/useTheme";

const drawerWidth = "100vw";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#EEF0FF",
  padding: "10px 12px",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  fontSize: "1rem",
  letterSpacing: 0,
  // minHeight: "64px",
  borderRadius: 0,
  backgroundColor: "transparent",
  lineHeight: "20px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: "100%",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.light,
    zIndex: -1,
    transition: "right 0.3s ease-out",
  },
  "&:hover::before": {
    right: 0,
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  color: theme.palette.white.main,
  padding: "15px 20px",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  fontSize: "15px",
  letterSpacing: 0,
  // height: "34px",
  borderRadius: "5px",
  border: "1px solid #000000",
  lineHeight: 1,
  backgroundColor: theme.palette.secondary.light,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white.main,
  },
}));

const TakePartButton = styled(Button)(({ theme }) => ({
  color: "#EEF0FF",
  padding: "4px 20px",
  textTransform: "none",
  fontSize: "1.1rem",
  letterSpacing: 0,
  height: "34px",
  borderRadius: "50px",
  backgroundColor: "transparent",
  border: "3px solid #EEF0FF",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    color: "#EEF0FF",
  },
}));

function ResponsiveAppBar({ window, setProposalLogs }) {
  const theme = useTheme();
  const [user, errorAuth] = useAuthState(auth);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  const customClaims = user
    ? JSON.parse(user?.reloadUserInfo?.customAttributes)
    : null;
  const userRoles = loggedInUser ? customClaims?.role : [];
  const username = user?.email;
  const userInitials = username?.charAt(0).toUpperCase();
  const isFund =
    customClaims?.user_type === "Pension" ||
    customClaims?.user_type === "Asset Manager";
  const location = useLocation();

  useEffect(() => {
    setLoggedInUser(user ? true : false);
    if (errorAuth) console.log(errorAuth);
  }, [user, errorAuth]);

  const logout = (e) => {
    e.preventDefault();
    auth
      .signOut()
      .then(function () {
        alert("You have been logged out");
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const redirectToContact = () => {
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const section = searchParams.get("section");
    if (section === "contact") {
      return `${location.pathname}?section=contact-us`;
    }
    return `${location.pathname}?section=contact`;
  };

  const homepageNavi = (orientation) => {
    return (
      <ButtonGroup
        orientation={orientation}
        variant="text"
        color="primary"
        aria-label="text button group"
        className="nav-group"
      >
        <ColorButton
          component={RouterLink}
          className={location.pathname === "/about" ? "link-active" : ""}
          to="/about"
        >
          About
        </ColorButton>
        <ColorButton
          component={RouterLink}
          className={
            location.pathname === "/voting-records" ? "link-active" : ""
          }
          to="/voting-records"
          onClick={() => setProposalLogs(false)}
        >
          Voting Records
        </ColorButton>
        <ColorButton
          component={RouterLink}
          className={location.pathname === "/" ? "link-active" : ""}
          to="/"
        >
          Database
        </ColorButton>
      </ButtonGroup>
    );
  };

  const databaseNavi = (orientation) => {
    return (
      <ButtonGroup
        orientation={orientation}
        sx={orientation === "vertical" ? { width: "100%" } : {}}
        variant="text"
        color="primary"
        aria-label="text button group"
        className={orientation === "horizontal" && "nav-group"}
      >
        {orientation === "vertical" ? (
          <ColorButton
            component={RouterLink}
            className={location.pathname === "/" ? "link-active" : ""}
            to="/"
          >
            Database
          </ColorButton>
        ) : null}
        <ColorButton
          component={RouterLink}
          className={location.pathname === "/graphs" ? "link-active" : ""}
          to="/graphs"
        >
          Graphs
        </ColorButton>
        <ColorButton
          component={RouterLink}
          className={
            location.pathname === "/voting-records" ? "link-active" : ""
          }
          to="/voting-records"
          onClick={() => setProposalLogs(false)}
        >
          Voting Records
        </ColorButton>
        {loggedInUser &&
          includesUserRoles(userRoles, ["EditProposals", "Admin"]) && (
            <ColorButton
              component={RouterLink}
              className={location.pathname === "/edit" ? "link-active" : ""}
              to="/edit"
              onClick={() => setProposalLogs(false)}
            >
              Proposals
            </ColorButton>
          )}
        {loggedInUser &&
          includesUserRoles(userRoles, ["EditCompanies", "Admin"]) && (
            <ColorButton
              component={RouterLink}
              className={
                location.pathname === "/editCompany" ? "link-active" : ""
              }
              to="/editCompany"
            >
              Companies
            </ColorButton>
          )}
        {loggedInUser &&
          includesUserRoles(userRoles, ["EditInvestors", "Admin"]) && (
            <ColorButton
              component={RouterLink}
              className={location.pathname === "/editFund" ? "link-active" : ""}
              to="/editFund"
            >
              Investors
            </ColorButton>
          )}
        {loggedInUser && includesUserRoles(userRoles, ["Upload", "Admin"]) && (
          <ColorButton
            component={RouterLink}
            className={location.pathname === "/uploads" ? "link-active" : ""}
            to="/uploads"
          >
            Upload Files
          </ColorButton>
        )}
        {!loggedInUser && orientation === "vertical" && (
          <LoginButton
            style={{ borderRadius: 0, width: "100%" }}
            component={RouterLink}
            to="/login"
          >
            <Typography component="span">Log in</Typography>
          </LoginButton>
        )}
      </ButtonGroup>
    );
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ display: "block", backgroundColor: "primary.main" }}
    >
      <Typography sx={{ padding: "0 30px", my: 2, color: "white.primary" }}>
        MENU
      </Typography>
      <Divider />
      {databaseNavi("vertical")}
    </Box>
  );

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 1,
      }}
    >
      <CssBaseline />
      <AppBar
        className={
          !location.pathname.includes("home") &&
          !location.pathname.includes("about") &&
          location.pathname !== "/"
            ? "nav-wrapper home-page"
            : "nav-wrapper home-page"
        }
        position="relative"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth="xl" className="nav-container p-0">
          <Toolbar
            disableGutters
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <Box
              sx={{
                justifyContent: "space-between",
                minWidth: "20%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box component="div" className="elementor-widget-container">
                <Typography
                  variant="h5"
                  component="a"
                  href="https://oxprox.org"
                  sx={{
                    // mr: 2,
                    display: { xs: "none", md: "flex" },
                    //flexGrow: 1,
                    // fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <img
                    alt="Oxprox Logo"
                    src={logo}
                    // width="90px"
                    // height="90px"
                    href="https://oxprox.org"
                    className="align-top nav-logo-img"
                  />
                </Typography>
              </Box>

              {
                /*!location.pathname.includes("home") &&
              !location.pathname.includes("about") &&
              !location.pathname.includes("insights") ? (
                <ColorButton component={RouterLink} to="/insights">
                  Home
                </ColorButton>
              ) : (
                homepageNavi("horizontal")
              )*/

                <ButtonGroup
                  orientation="horizontal"
                  variant="text"
                  color="primary"
                  aria-label="text button group"
                >
                  <ColorButton
                    component={RouterLink}
                    className={location.pathname === "/" ? "link-active" : ""}
                    to="/"
                    sx={{
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    Database
                  </ColorButton>
                </ButtonGroup>
              }
            </Box>
            <Box
              sx={{
                //flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 0, display: { sm: "flex" } }}
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
            </Box>
            <Typography
              variant="h5"
              component="a"
              href="https://oxprox.org"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                alt="Oxprox Logo"
                src={logo}
                width="80"
                href="https://oxprox.org"
                className="align-top pl-2"
              />
            </Typography>
            <Box
              sx={{
                gap: "2.2% 2%",
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <>
                {
                  /*!location.pathname.includes("home") &&
                !location.pathname.includes("about") &&
                !location.pathname.includes("insights") ? (*/
                  <>
                    {databaseNavi("horizontal")}
                    {loggedInUser ? (
                      <Box>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar sx={{ bgcolor: theme.palette.secondary.light }}>
                            {userInitials}
                          </Avatar>
                        </IconButton>
                        <Menu
                          sx={{ mt: "45px" }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          <ListItem key={"Profile"} sx={{ pb: 0 }}>
                            <ListItemText
                              sx={{ color: theme.palette.black.light }}
                            >
                              {user?.displayName}
                            </ListItemText>
                          </ListItem>
                          <ListItem key={"Email"} sx={{ pt: 0 }}>
                            <Typography
                              sx={{
                                display: "block",
                                color: theme.palette.black.light,
                                fontSize: "0.8rem",
                              }}
                            >
                              {username}
                            </Typography>
                          </ListItem>
                          {isFund && (
                            <ListItem key={"Email"} sx={{ pt: 0 }}>
                              <Typography
                                sx={{
                                  display: "block",
                                  color: theme.palette.black.light,
                                  fontSize: "0.8rem",
                                }}
                              >
                                Investor
                              </Typography>
                            </ListItem>
                          )}
                          <Divider />
                          <MenuItem
                            key={"Admin Panel"}
                            component={RouterLink}
                            to="/admin-panel"
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon icon={faAddressCard} />
                            </ListItemIcon>
                            <ListItemText textalign="center">
                              Admin Panel
                            </ListItemText>
                          </MenuItem>
                          <MenuItem key={"Logout"} onClick={logout}>
                            <ListItemIcon>
                              <FontAwesomeIcon icon={faDoorClosed} />
                            </ListItemIcon>
                            <ListItemText textalign="center">
                              Log out
                            </ListItemText>
                          </MenuItem>
                        </Menu>
                      </Box>
                    ) : (
                      <LoginButton component={RouterLink} to="/login">
                        Log in
                      </LoginButton>
                    )}
                  </>
                  /*) : (
                  <TakePartButton
                    component={RouterLink}
                    to={redirectToContact()}
                  >
                    Take part now
                  </TakePartButton>
                )*/
                }
              </>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          anchor="top"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

/**
 * <p>Navigation menu for the whole application using reac router.</p>
 * @param {Object} props
 * @param {function} props.setProposalLogs - changes the state of the logs mode for the proposal database component
 * @returns rendered view of Navmenu
 * @author valeriaxeleva
 */
const Navmenu = ({ setProposalLogs }) => {
  return <ResponsiveAppBar setProposalLogs={setProposalLogs} />;
};

export default Navmenu;
