import React, { useState, useEffect, useRef } from "react";
import countryList from "react-select-country-list";
import { Button } from "react-bootstrap";
import { Formik, Form as FormFormik } from "formik";
import {
  FormikTextInput as TextFieldFormik,
  FormikSelect as Select,
} from "../utils/forms";
import * as Yup from "yup";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSASBLists } from "../../contexts/subjectsProvider";
import { BaseModal } from "../Layout/BaseModal";

const AutocompleteField = ({
  setFieldValue,
  array,
  setArray,
  valueName,
  label,
}) => {
  return (
    <div
      style={{
        maxWidth: "400px",
        overflow: "scroll",
        maxHeight: "60px",
        marginBottom: "15px",
      }}
    >
      {
        <Autocomplete
          multiple
          id="tags-filled"
          disableClearable
          options={[].map(() => {
            return null;
          })}
          value={array.length > 0 ? array : ["Example"]}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={(e, chips) => {
            setArray(() => [...chips]);
            setFieldValue(valueName, [...chips]);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              placeholder=""
              label={label}
            />
          )}
          sx={{ div: { backgroundColor: "ghostwhite" } }}
        />
      }
    </div>
  );
};

/**
 * Component that renders the form to edit a company.
 * @param {Object} props
 * @param {function} props.editCompany - Function to update the current company
 * @param {Object} props.currentEditCompany - Ref with the information of the current company editing
 * @param {function} props.setCurrentEditCompany - Formik's funtion to reset the form values
 * @param {boolean} props.showModal - True if the modal that contains the form is visible, false i.o.c.
 * @param {function} props.setShowModal - Function to change the state of showModal
 * @returns the rendered component
 * @author valeriaxeleva
 */

export const EditCompanyForm = ({
  setCurrentEditCompany,
  editCompany,
  setShowModal,
  showModal,
  currentEditCompany,
}) => {
  const { SASBCompaniesList } = useSASBLists();

  //constraints of the edit Modal´s formik
  const companySchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const countries = countryList().getData();
  const [sector, setSector] = useState("default");
  const [isin, setIsin] = useState([]);
  const [aliasesCurrentCompany, setAliasesCurrentCompany] = useState([]);
  const [showISINerrorMessage, setShowISINerrorMessage] = useState(false);
  const [industries, setIndustries] = useState([]);
  const formRef = useRef();

  useEffect(() => {
    setSector(currentEditCompany.sector);
    if (currentEditCompany.ISINs?.length > 0) setIsin(currentEditCompany.ISINs);
    else setIsin([]);
    if (currentEditCompany.aliases?.length > 0)
      setAliasesCurrentCompany(currentEditCompany.aliases);
    else setAliasesCurrentCompany([]);
    //Disable the warning because we want to execute this effect only when showModal changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    const industries = SASBCompaniesList.filter((item) => item.name === sector);
    if (industries.length > 0) {
      setIndustries(industries[0].subs);
    }
    setCurrentEditCompany({ ...currentEditCompany, sector: sector });
    if (sector === "default") setIndustries([]);

    //Disable the warning because we want to execute this effect only when sector and SASBCompaniesList change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sector, SASBCompaniesList]);

  useEffect(() => {
    setCurrentEditCompany({
      ...currentEditCompany,
      aliases: aliasesCurrentCompany,
    });
    // Disable the warning because we want to execute this effect only when aliasesCurrentCompany changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasesCurrentCompany]);

  useEffect(() => {
    setCurrentEditCompany({
      ...currentEditCompany,
      ISINs: isin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isin]);

  const handleSubmitEdit = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    editCompany(values);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <BaseModal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Edit Company"
    >
      <BaseModal.Body>
        <Formik
          innerRef={formRef}
          initialValues={{
            _id: currentEditCompany?._id,
            name: currentEditCompany?.name,
            sector: currentEditCompany?.sector
              ? currentEditCompany?.sector
              : "default",
            subsector: currentEditCompany?.subsector
              ? currentEditCompany?.subsector
              : "default",
            industry: currentEditCompany?.industry
              ? currentEditCompany?.industry
              : "default",
            country: currentEditCompany?.country
              ? currentEditCompany?.country
              : "default",
            ISINs: currentEditCompany?.ISINs ? currentEditCompany?.ISINs : [],
            aliases: currentEditCompany?.aliases
              ? currentEditCompany?.aliases
              : [],
          }}
          validationSchema={companySchema}
          onSubmit={handleSubmitEdit}
        >
          {({ setFieldValue }) => (
            <FormFormik id="editCompanyForm">
              <TextFieldFormik
                className="w-100"
                name="name"
                type="text"
                label="Name"
              />

              <Select label="Country" name="country">
                <option value="default" key="default">
                  --None
                </option>
                {countries.map((category) => (
                  <option value={category.label} key={category.label}>
                    {category.label}
                  </option>
                ))}
              </Select>

              <Select
                label="Sector"
                name="sector"
                onFocus={() => {
                  if (showISINerrorMessage) setShowISINerrorMessage(false);
                }}
                onChange={(e) => {
                  if (
                    e.target.value === "default" &&
                    currentEditCompany.ISINs.length > 0
                  ) {
                    if (currentEditCompany.ISINs.join("") !== isin.join(""))
                      setShowISINerrorMessage(true);
                    if (isin)
                      // set the original ISIN
                      setCurrentEditCompany({
                        ...currentEditCompany,
                        sector: e.target.value,
                        industry: "default",
                        ISINs: isin,
                      });
                    // remove the wrote isin
                    else
                      setCurrentEditCompany({
                        ...currentEditCompany,
                        sector: e.target.value,
                        industry: "default",
                        ISINs: [],
                      });
                  } else
                    setCurrentEditCompany({
                      ...currentEditCompany,
                      sector: e.target.value,
                      industry: "default",
                    });
                  setFieldValue("sector", e.target.value);
                  setSector(e.target.value);
                }}
              >
                <option value="default" key="default">
                  --None
                </option>
                {SASBCompaniesList.map((category) => (
                  <option value={category.name} key={category.name}>
                    {category.name}
                  </option>
                ))}
              </Select>

              <Select
                label="Industry"
                name="industry"
                disabled={currentEditCompany.sector === "default"}
                onFocus={() => {
                  if (showISINerrorMessage) setShowISINerrorMessage(false);
                }}
                onChange={(e) => {
                  if (
                    e.target.value === "default" &&
                    currentEditCompany.ISINs.length > 0
                  ) {
                    if (currentEditCompany.ISINs.join("") !== isin.join(""))
                      setShowISINerrorMessage(true);
                    if (isin.length > 0)
                      setCurrentEditCompany({
                        ...currentEditCompany,
                        industry: e.target.value,
                        ISINs: isin,
                      });
                    else
                      setCurrentEditCompany({
                        ...currentEditCompany,
                        industry: e.target.value,
                        ISINs: [],
                      });
                  } else
                    setCurrentEditCompany({
                      ...currentEditCompany,
                      industry: e.target.value,
                    });
                  setFieldValue("industry", e.target.value);
                }}
              >
                <option value="default" key="default">
                  --None
                </option>
                {industries.map((category, index) => (
                  <option value={category} key={category}>
                    {category}
                  </option>
                ))}
              </Select>

              <AutocompleteField
                setFieldValue={setFieldValue}
                array={isin}
                setArray={setIsin}
                valueName="ISINs"
                label="ISIN"
              />

              {showISINerrorMessage &&
                (!currentEditCompany.industry ||
                  !currentEditCompany.sector ||
                  currentEditCompany.industry === "default" ||
                  currentEditCompany.sector === "default") && (
                  <p className="error-message">
                    A Sector and an industry are neccessary to specify or update
                    an ISIN
                  </p>
                )}

              <AutocompleteField
                setFieldValue={setFieldValue}
                array={aliasesCurrentCompany}
                setArray={setAliasesCurrentCompany}
                valueName="aliases"
                label="Aliases"
              />
            </FormFormik>
          )}
        </Formik>
      </BaseModal.Body>
      <BaseModal.Footer>
        <Button type="submit" onClick={handleSubmit} variant="success">
          Save Changes
        </Button>
      </BaseModal.Footer>
    </BaseModal>
  );
};
