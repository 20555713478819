import { Children } from "react";
import { Helmet } from "react-helmet-async";
import { Button, ButtonGroup, Typography, Box } from "@mui/material";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Body = () => null;
const Actions = () => null;

/**
 * Template for the main content of the Editor + Logs OxProx pattern.
 * @param {Object} props
 * @param {string} props.title - Title of the page
 * @param {node} props.children - Children elements to be rendered on the main page.
 * @param {function} props.buttonLogs- The button used to change to the logs view and viceversa.
 * @returns the rendered component
 * @author valeriaxeleva
 */
export const MainContent = ({
  title,
  children,
  open,
  handleDrawerOpen,
  single,
  hasSideBar = true,
  colorTitle
}) => {
  children = Children.toArray(children);
  const body = children.find((el) => el.type === Body);
  const actions = children.find((el) => el.type === Actions);
  return (
    <Box sx={{ marginTop: "70px" }}>
      <Helmet>
        <title>OxProx | {title}</title>
      </Helmet>
      <Box sx={{ height: "90%", width: "100%" }}>
        <Box sx={{ mb: "10px" }}>
          <Box
            sx={{
              width: "100%",
              display: { xs: "block", md: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box className="d-flex d-row" sx={{ alignItems: "center" }}>
              {!single && hasSideBar && (
                <Button
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    margin: 1,
                    marginLeft: "-10px",
                    textTransform: "none",
                    fontSize: "0.9rem",
                    lineHeight: "1",
                    color: "#5f687b",
                    ...(open && { display: "none" }),
                  }}
                  startIcon={
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: "30px" }}
                    />
                  }
                >
                  Show <br />
                  Filters
                </Button>
              )}
              <Typography
                id="article_title"
                sx={{
                  // fontSize: { xs: "1.25rem", md: "1.5rem" },
                  // fontWeight: 500,
                  fontFamily: "Poppins",
                  padding: "16px 0",
                }}
                component="h2"
                variant="h2"
                color={colorTitle}
              >
                {title}
              </Typography>
            </Box>
            <Box>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined primary button group"
                sx={{
                  "& button": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    textTransform: "none",
                    padding: { xs: "5px 10px", md: "5px 15px" },
                    lineHeight: { xs: "1", md: "inherit" },
                  },
                }}
              >
                {actions ? actions.props.children : null}
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
        {body ? body.props.children : null}
      </Box>
    </Box>
  );
};

MainContent.Body = Body;
MainContent.Actions = Actions;
