import React, { useRef } from "react";
import countryList from "react-select-country-list";
import { Button, Col, Form } from "react-bootstrap";
import { Formik, Form as FormFormik } from "formik";
import {
  FormikTextInput as TextFieldFormik,
  FormikSelect as Select,
} from "../utils/forms";
import * as Yup from "yup";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BaseModal } from "../Layout/BaseModal";

/**
 * Component that renders the form
 * @param {Object} props
 * @param {function} props.editFund - Function to update the current company
 * @param {Array} props.aliasChips - Array of strings corresponding to the stored aliases of a company
 * @param {function} props.setAliasChips - Function to change the state of the aliasChips hook.
 * @param {function} props.handleCloseEdit - Function to close the edition modal
 * @param {Object} props.currentFundEdit - Ref with the information of the current company editing
 * @param {boolean} props.showModal - True if the modal that contains the form is visible, false i.o.c.
 * @param {function} props.setShowModal - Function to change the state of showModal
 * @returns the rendered component
 * @author milenexeleva
 */
export const EditFundForm = ({
  editFund,
  aliasChips,
  setAliasChips,
  handleCloseEdit,
  showModal,
  setShowModal,
  currentFundEdit,
}) => {
  const countries = countryList().getData();
  const formRef = useRef();

  const fundSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .max(100, "Must be 100 characters or less"),
  });

  const handleSubmitEdit = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    editFund(values);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };


  return (
    <BaseModal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Edit Fund"
    >
        <BaseModal.Body>
        <Formik
        innerRef={formRef}
        initialValues={{
          _id: currentFundEdit?._id,
          name: currentFundEdit?.name,
          country: currentFundEdit?.country,
          acronym: currentFundEdit?.acronym,
          aliases: currentFundEdit?.aliases,
        }}
        validationSchema={fundSchema}
        enableReinitialize
        onSubmit={handleSubmitEdit}
      >
        {({ setFieldValue }) => (
          <FormFormik id="editFundForm">
            <Form.Group as={Col} controlId="formGridName">
              <TextFieldFormik
                className="w-100"
                name="name"
                type="text"
                label="Name"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAcronym">
              <TextFieldFormik
                className="w-100"
                name="acronym"
                type="text"
                label="Acronym"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGriCountry">
              <Select label="Country" name="country">
                <option value="default" key="default">
                  --None
                </option>
                {countries.map((category, index) => (
                  <option value={category.label} key={category.label}>
                    {category.label}
                  </option>
                ))}
              </Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAlias">
              <div
                className="overflow-auto"
                style={{
                  maxWidth: "400px",
                  maxHeight: "45px",
                  overflow: "scroll",
                }}
              >
                <Autocomplete
                  multiple
                  id="tags-filled"
                  disableClearable
                  options={[].map(() => {return null})}
                  value={aliasChips || ["Alias Example"]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(e, chips) => {
                    setAliasChips(() => [...chips]);
                    setFieldValue("aliases", [...chips]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label={"Aliases"}
                      placeholder=""
                    />
                  )}
                  sx={{ div: { backgroundColor: "ghostwhite" } }}
                />
              </div>
            </Form.Group>
          </FormFormik>
        )}
      </Formik>
        </BaseModal.Body>
        <BaseModal.Footer>
        <Button type="submit" className="m-2" onClick={handleSubmit} variant="success">
                Save Changes
              </Button>
              <Button
                className="m-2"
                variant="danger"
                onClick={handleCloseEdit}
              >
                Cancel
              </Button>
        </BaseModal.Footer>
   
    </BaseModal>
  );
};
