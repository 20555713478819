import { Children, useState, cloneElement, useEffect } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";

const drawerWidth = 300;
const drawerWidthMobile = "100vw";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, ismobile }) => ({
    flexGrow: 1,
    padding: "0 1rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 1rem 0",
    },
    width: "100%",
    scrollBehavior: "smooth",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: ismobile ? `inherit` : `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: "90px",
}));

/**
 * Template for the content after the top navigation.
 * @param {Object} props
 * @param {node} props.children - Children elements to be rendered on the main page.
 * @param {boolean} props.single - If the page is a single page or not.
 * @returns the rendered component
 * @author valeriaxeleva
 */
export const Page = ({ children, single }) => {
  const [open, setOpen] = useState(true);
  const isMobile = useMediaQuery("(max-width:912px)");
  let mainIndex = single ? 0 : 1;

  useEffect(() => {
    setOpen(isMobile ? false : true);
  }, [isMobile]);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box className="grid-container">
      <CssBaseline />
      {!single && (
        <Drawer
          sx={{
            width: { xs: drawerWidthMobile, md: drawerWidth },
            position: "relative",
            overflow: "hidden",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { xs: drawerWidthMobile, md: drawerWidth },
              boxSizing: "border-box",
              border: "none",
              boxShadow: "5px 0px 10px 0px rgba(0, 0, 0, 0.1);",
              zIndex: 0,
            },
          }}
          variant={isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            className: "patternNavy content-box"
          }}
        >
          <DrawerHeader sx={{color: "white.main"}}>OxProx</DrawerHeader>

          {cloneElement(Children.toArray(children)[0], {
            open: open,
            handleDrawerOpen: handleDrawerOpen,
            handleDrawerClose: handleDrawerClose,
          })}
        </Drawer>
      )}
      <Main open={open} ismobile={isMobile ? true : undefined}>
        {cloneElement(Children.toArray(children)[mainIndex], {
          open: open,
          handleDrawerOpen: handleDrawerOpen,
        })}
      </Main>
    </Box>
  );
};

export default Page;
