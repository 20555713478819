import { useState, useCallback, useRef } from "react";
import Button from "react-bootstrap/Button";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as CompanyModal } from "../SearchModals/ModalCompanies";
import { default as FundModalWithTabs } from "../SearchModals/ModalFund";
import { Formik, Field, Form as FormFormik } from "formik";
import * as Yup from "yup";
import { MenuDropdown } from "./MenuDropdown";
import { ProposalSubjectsAccordion } from "./ProposalSubjectsAccordion";
import { useSASBLists } from "../../contexts/subjectsProvider";
import InfoTooltip from "../InfoTooltip";
import Link from '@mui/material/Link';
import { Alert } from "@mui/material";
/**
 * Dropdown menu for the side menu of the proposal editor with the SASB proposal subjects.
 * @param {Object} props - props
 * @param {string} props.type - "company" or "proposal"
 * @param {string} props.formikName - name of the formik field
 * @param {Object} props.values - formik values
 * @param {function} props.setFieldValue - formik setFieldValue
 * @param {Object} props.dropdowns - dropdowns state
 * @param {function} props.toggleDropdowns - dropdowns state setter
 * @returns - rendered view of SubjectsDropdown component
 * @author valeriaxeleva
 */
const SubjectsDropdown = ({
  type,
  formikName,
  values,
  setFieldValue,
  dropdowns,
  toggleDropdowns,
}) => {
  const { SASBCompaniesList, SASBProposalsList } = useSASBLists();
  const isProposal = type === "proposal";
  const subjects = isProposal ? SASBProposalsList : SASBCompaniesList;
  const dropdownName = isProposal ? "ps" : "cs";

  const handleDropdowns = useCallback(
    (newValue) => {
      toggleDropdowns({ ...dropdowns, ...newValue });
    },
    [dropdowns, toggleDropdowns]
  );

  return (
    <>
      {subjects.map((parentSubject, index) => (
        <div key={parentSubject.name} className="d-flex">
          <Field
            id={parentSubject.name}
            checked={values[formikName].some((s) =>
              parentSubject.subs.includes(s)
            )}
            className="sub_root"
            type="checkbox"
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setFieldValue(formikName, [
                  ...new Set([...values[formikName], ...parentSubject.subs]),
                ]);
              } else
                setFieldValue(
                  formikName,
                  values[formikName].filter((subject) => {
                    return !parentSubject.subs.includes(subject);
                  })
                );
            }}
          />

          <div className="subjects_advanced w-100">
            <div
              className="sub-drop"
              onClick={() =>
                handleDropdowns({
                  [dropdownName]: !dropdowns[dropdownName]
                    ? subjects.map((_, i) => index === i)
                    : subjects.map((_, i) =>
                        index === i
                          ? !dropdowns[dropdownName][i]
                          : dropdowns[dropdownName][i]
                      ),
                })
              }
            >
              <div className="d-flex align-items-center">
                <label className="small-font sub-label">
                  {parentSubject.name}
                </label>
              </div>
              <span>
                <FontAwesomeIcon
                  icon={
                    !dropdowns[dropdownName]
                      ? faAngleDown
                      : dropdowns[dropdownName][index]
                      ? faAngleUp
                      : faAngleDown
                  }
                />
              </span>
            </div>
            <div
              className="show_industries"
              style={{
                display:
                  dropdowns[dropdownName] && dropdowns[dropdownName][index]
                    ? "block"
                    : "none",
              }}
            >
              <ul className="sub_funds my-2">
                {parentSubject.subs.map((childSubject) => (
                  <li key={`${childSubject.replace(" ", "-")}`}>
                    <Field
                      value={childSubject}
                      id={`${parentSubject.name}-${childSubject}`}
                      name={formikName}
                      type="checkbox"
                    />
                    <label
                      className="small-font sub-label"
                      htmlFor={`${parentSubject.name}-${childSubject}`}
                    >
                      {childSubject}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

/**
 * <p>Side menu component for main or home page.</p>
 * <p>Displays filters and actions to execute on home page.</p>
 * @param {Object} props
 * @param {boolean} props.editorMode - true if the view is /edit false if the view is home page
 * @param {Array} props.selectedProposals - selected proposals in the data grid
 * @param {function} props.searchProposals - searches for paginated proposals
 * @param {Array} props.proposals - proposals of the table
 * @param {function} props.setProposals - sets proposals of the table
 * @param {Object} props.formikRef - reference to form in side menu
 * @param {boolean} props.isAdvancedSearch - true if side menu displays all form fields, false i.o.c
 * @param {function} props.setIsAdvancedSearch - state hook for isAdvancedSearch
 * @param {function} props.selectedProposalsPersistent - state hook for selectedProposals
 * @param {boolean} props.isAssignSubjects - true if side menu displays all form fields, false i.o.c
 * @param {function} props.setIsAssignSubjects - state hook for isAssignSubjects
 * @param {Object} props.user - user object
 * @returns rendered view of SideMenu component
 * @author rafaelg57
 * @author valeriaxeleva
 */
const SideMenu = ({
  editorMode,
  selectedProposals,
  searchProposals,
  proposals,
  setProposals,
  formikRef,
  isAdvancedSearch,
  setIsAdvancedSearch,
  selectedProposalsPersistent,
  isAssignSubjects,
  setIsAssignSubjects,
  user,
}) => {
  // Toggles/Buttons
  const [dropdowns, toggleDropdowns] = useState({});
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showFundModalWithTabs, setShowFundModalWithTabs] = useState(false);
  const [errorMsg,setErrorMsg] = useState({open:false,message:""})
  const { SASBProposalsList } = useSASBLists();
  //aux
  const resetSelectedCompaniesinModal = useRef(null);
  const resetSelectedFundsModal = useRef(null);

  const validationSchema = Yup.object().shape(
    {
    meetingStartDate: Yup.date()
      .min(
        editorMode ? "2005-01-01" : "2015-07-01",
        `Meeting start date must be later than ${
          editorMode ? "2005-01-01" : "2015-07-01"
        }`
      )
      .required("Please select a valid start Meeting Date"),
    meetingEndDate: Yup.date().when(
      "meetingStartDate",
      (startDate, schema) => {
        return startDate && schema.min(startDate,"Please make sure you have selected a valid Meeting Date range");
      }
    )
    .required("Please select a valid end Meeting Date"),
    funds: editorMode? Yup.array():Yup.array()
      .min(1, "Please make sure you have selected at least one Investor")
      .required(),
    industries:Yup.array().when('companies', {
      is: (companies) => !companies || companies.length === 0,
      then: Yup.array()
        .min(1,'Please make sure you have selected at least one Company or Industry').required(),
        otherwise: Yup.array()
    }),
    companies: Yup.array().when('industries', {
      is: (industries) => !industries || industries.length === 0,
      then: Yup.array()
        .min(1,'Please make sure you have selected at least one Company or Industry').required(),
        otherwise: Yup.array()
    })
  },["companies","industries"])

  const currentMonth =
    (new Date().getMonth() + 1).toString().length > 1
      ? new Date().getMonth() + 1
      : "0" + (new Date().getMonth() + 1);
  const currentDay =
    new Date().getDate().toString().length > 1
      ? new Date().getDate()
      : "0" + new Date().getDate();

  // Calculates min or max dates for Date Range
  const getDate = useCallback(
    (type) => {
      const today = new Date();
      if (type === "min")
        return isAdvancedSearch || editorMode ? "2005-01-01" : "2015-07-01";

      if (type === "max") return `${today.getFullYear() + 1}-06-30`;
    },
    [isAdvancedSearch, editorMode]
  );

  return (
    <div className={editorMode ? "edition-mode h-100" : "h-100"}>
      {user &&
        (!editorMode ? (
          <Button
            variant="link"
            size="sm"
            className="advanced_search_btn"
            onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}
          >
            Go to {!isAdvancedSearch ? "Advanced Search" : "Simplified Search"}
          </Button>
        ) : (
          <Button
            variant="link"
            size="sm"
            className="advanced_search_btn"
            onClick={() => setIsAssignSubjects(!isAssignSubjects)}
          >
            {!isAssignSubjects ? "Assign subjects" : "Go back to search form"}
          </Button>
        ))}

      <div style={{ display: isAssignSubjects ? "block" : "none" }}>
        <ProposalSubjectsAccordion
          persistent={selectedProposalsPersistent}
          {...{
            SASBProposalsList,
            setProposals,
            proposals,
            selectedProposals,
            user,
          }}
        />
      </div>

      <Formik
        initialValues={{
          subjects: [],
          industries: [],
          companies: [],
          funds: [],
          meetingStartDate: `${new Date().getFullYear() - 1}-01-01`,
          meetingEndDate: `${new Date().getFullYear()}-${currentMonth}-${currentDay}`,
          managementRecommendation: "",
          proponent: "",
          financiallyMaterial: "",
          keywords: "",
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const nonFalsyValues = Object.keys(values).filter((key) => {
            const cond1 = typeof values[key] === "string" && values[key] !== "";
            const cond2 =
              typeof values[key] === "object" && !!values[key].length;
            return cond1 || cond2;
          });

          const body = {};
          nonFalsyValues.forEach((key) => (body[key] = values[key]));
          body.editMode = editorMode;

          if (editorMode) {
            const { funds, ...rest } = body;
            await searchProposals(rest);
          } else if (isAdvancedSearch) await searchProposals(body);
          else {
            let {
              subjects,
              industries,
              meetingStartDate,
              meetingEndDate,
              companies,
              funds,
              editMode,
            } = body;
            await searchProposals({
              subjects,
              industries,
              meetingStartDate,
              meetingEndDate,
              companies,
              funds,
              editMode,
            });
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          setFieldValue,
          resetForm,
          dirty,
          errors,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <FormFormik
            className="h-100 mt-4"
            style={{ display: isAssignSubjects ? "none" : "block" }}
          >
            <div className="form_xl">
              {/* Investors */}
              <MenuDropdown title="Investors" hide={editorMode}>
                {values.funds.length > 0 && (
                  <textarea
                    className="w-100 mb-1"
                    value={values.funds.map((fund) => fund.name)}
                    readOnly
                    style={{ resize: "none" }}
                    rows={4}
                  />
                )}

                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowFundModalWithTabs(true)}
                >
                  Show list of investors
                </button>
              </MenuDropdown>

              {/* Companies */}
              <MenuDropdown title="Companies">
                {values.companies.length > 0 && (
                  <textarea
                    className="w-100 mb-1"
                    value={values.companies}
                    readOnly
                    style={{ resize: "none" }}
                    rows={4}
                  />
                )}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowCompanyModal(true)}
                >
                  Show Companies List
                </button>
              </MenuDropdown>

              {/* Company classification - Industries */}
              <MenuDropdown title={
                <>
                Industries 
                <InfoTooltip color="white.main" tooltipText={
                  <>
                  Use this filter if you want to see an investor’s voting records for an entire industry. Note: Industry categories are based on the  {" "}
                  <Link href="https://sasb.ifrs.org/wp-content/uploads/2018/11/SICS-Industry-List.pdf" target="_blank" rel="noopener" color="inherit" onClick={(event) =>  event.stopPropagation()}>
                   Sustainable Industry Classification System
                  </Link>
                  .
                  </>
              }
              />
                </>
                }>
                <SubjectsDropdown
                  type="company"
                  formikName="industries"
                  {...{ values, setFieldValue, toggleDropdowns, dropdowns }}
                />
              </MenuDropdown>

              {/* Date range - Meeting Date*/}
              <MenuDropdown
                title={
                  <>
                    Meeting Date{" "}
                    <InfoTooltip  color="white.main" tooltipText="Choose the date or date range of corporate meeting(s) to view corresponding proxy voting records. Note: Proxy periods run from July 1 to June 30." />
                  </>
                }
              >
                <div className="mt-1 mb-2">
                  <label className="mb-0 mr-2">Proxy period</label>
                  <select
                    className="w-100"
                    onChange={(e) => {
                      const { value } = e.target;
                      const startDate = !value
                        ? getDate("min")
                        : `${value}-07-01`;
                      const endDate = !value
                        ? ""
                        : `${parseInt(value) + 1}-06-30`;
                      setFieldValue("meetingStartDate", startDate);
                      setFieldValue("meetingEndDate", endDate);
                      setFieldTouched("meetingStartDate");
                      setFieldTouched("meetingEndDate");
                    }}
                  >
                    <option value={""}>Choose a period...</option>
                    {Array(new Date().getFullYear() + 1 - 2015)
                      .fill()
                      .map((_, idx) => {
                        const start = 2015;
                        return (
                          <option
                            key={`${start + idx}-${start + idx + 1}`}
                            value={start + idx}
                          >
                            {start + idx}-{start + idx + 1}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="d-flex flex-column mt-1">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <span>From:</span>
                    <Field
                      name="meetingStartDate"
                      type="date"
                      min={getDate("min")}
                      max={getDate("max")}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column mt-1">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <span>To:</span>
                    <Field
                      name="meetingEndDate"
                      type="date"
                      min={getDate("min")}
                      max={getDate("max")}
                    />
                  </div>
                </div>
              </MenuDropdown>

              {/* Subjects - ESG Issues */}
              <MenuDropdown title="ESG Issues">
                <SubjectsDropdown
                  type="proposal"
                  formikName="subjects"
                  {...{ values, setFieldValue, toggleDropdowns, dropdowns }}
                />
              </MenuDropdown>

              {user && (
                <>
                  {/* Management Recommendation */}
                  <MenuDropdown
                    title="Mgmt Recommendation"
                    hide={!editorMode && !isAdvancedSearch}
                  >
                    <div>
                      <Field
                        name="managementRecommendation"
                        type="radio"
                        value="For"
                        id="mr-for"
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="mr-for" className="sub-label">
                        For
                      </label>
                    </div>
                    <div>
                      <Field
                        name="managementRecommendation"
                        type="radio"
                        value="Against"
                        id="mr-against"
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="mr-against" className="sub-label">
                        Against
                      </label>
                    </div>
                  </MenuDropdown>

                  {/* Proponent */}
                  <MenuDropdown
                    title="Proponent"
                    hide={!editorMode && !isAdvancedSearch}
                  >
                    <div>
                      <Field
                        name="proponent"
                        type="radio"
                        value="management"
                        id="proponent-mgmt"
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="proponent-mgmt" className="sub-label">
                        Management
                      </label>
                    </div>
                    <div>
                      <Field
                        name="proponent"
                        type="radio"
                        value="shareholder"
                        id="proponent-shrholdr"
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="proponent-shrholdr" className="sub-label">
                        Shareholder
                      </label>
                    </div>
                  </MenuDropdown>

                  {/* Financially Material */}
                  <MenuDropdown
                    title="Financially Material"
                    hide={!editorMode && !isAdvancedSearch}
                  >
                    <div>
                      <Field
                        name="financiallyMaterial"
                        type="radio"
                        value="true"
                        id="fm-yes"
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="fm-yes" className="sub-label">
                        Yes
                      </label>
                    </div>
                    <div>
                      <Field
                        name="financiallyMaterial"
                        type="radio"
                        value="false"
                        id="fm-no"
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="fm-no" className="sub-label">
                        No
                      </label>
                    </div>
                  </MenuDropdown>
                </>
              )}
              {/* Search by keywords 
              <div
                className="flex-column mt-2"
                style={{
                  display: !editorMode && !isAdvancedSearch ? "none" : "flex",
                }}
              >
                <Typography component="h5" className="sub-label mb-1 pl-0">Keywords</Typography>
                <Field
                  name="keywords"
                  className="keywords-input blue-input"
                  type="text"
                  autoComplete="off"
                />
              </div>*/}
            </div>
            <div className="mb-4">
              <div className="d-flex form-buttons-box">
                <Button
                  className="mt-1 flex-fill"
                  onClick={() => {
                    selectedProposalsPersistent.current = [];
                    setErrorMsg({open:false, message:""})
                    if(!dirty) errors = {funds:"Please make sure you have selected at least one Investor"}
                    if (Object.keys(errors).length > 0 ){
                      setErrorMsg({open:true,message:Object.values(errors)[0]})}
                    else handleSubmit();
                  }}
                  disabled={ isSubmitting }
                >
                  {
                    "Apply Filters" /*editorMode ? "Search Proposals" : "Search Votes"*/
                  }
                </Button>
                <Button
                  className="mt-1 ml-1 flex-fill"
                  onClick={() => {
                    resetForm();
                    resetSelectedCompaniesinModal.current();
                    resetSelectedFundsModal.current();
                  }}
                  type="reset"
                  variant="secondary"
                  disabled={!dirty}
                >
                  Clear
                </Button>
              </div>
              { errorMsg.open && (
              <Alert
                onClose={()=>setErrorMsg({open:false, message:""})}
                severity="error"
                variant="filled"
                sx={{ width: "100%", marginTop:"15px", backgroundColor:"#a52836" }}
              >
                {errorMsg.message}
              </Alert>
            )}
            </div>
          </FormFormik>
        )}
      </Formik>

      {/* Company Modal */}
      <CompanyModal
        show={showCompanyModal}
        catchCompanies={(selected) => {
          const companyNames = selected.map((elem) => elem.name);
          formikRef.current.setFieldValue("companies", companyNames);
        }}
        dialogClassName="modal-80w"
        handleClose={() => setShowCompanyModal(false)}
        resetSelectedCompaniesinModal={resetSelectedCompaniesinModal}
        isPublicSearch={!editorMode}
      />

      {/* Fund Modal */}
      <FundModalWithTabs
        show={showFundModalWithTabs}
        catchFunds={(selected) => {
          const mappedFunds = selected.map((item) => {
            return { name: item.name, hierarchy: item.hierarchy };
          });
          formikRef.current.setFieldValue("funds", mappedFunds);
        }}
        editView={false}
        handleClose={setShowFundModalWithTabs}
        resetSelectedFundsModal={resetSelectedFundsModal}
      />
    </div>
  );
};

export default SideMenu;
