import Application from "./Application";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { SubjectProvider } from "./contexts/subjectsProvider";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#092044',
      light: '#3c75b7', 
    },
    secondary: {
      main: '#760f27',
      light: '#a52836', 
    },
    black: {
      light: '#616161',
      main: '#000000',
    },
    blue:{
      deepDark: '#002147',
      dark: '#092044', 
      medium: '#363c46',
      light: '#3c75b7', 
      veryLight: '#a1cbf2',
    },
    red:{
      dark: '#400815', 
      medium: '#760f27',
      light: '#a52836', 
    },
    gray:{
      dark: '#66625e', 
      medium: '#a59d97',
      light: '#f3f1ee', 
      extraLight: '#E9E5E2',
    },
    white:{
      main:"#ffffff"
    },
    background: {
      default: '#f3f1ee',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontWeightLight: 400,
    fontSize: 16,
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
    },
    h1: {
      fontFamily: 'Libre Baskerville, sans-serif',
      fontWeight: 700,
      fontSize: 40
    },
    h2: {
      fontFamily: 'Libre Baskerville, sans-serif',
      fontWeight: 700,
      fontSize: 32,
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 700,
      fontSize: 28,
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: 24,
    },
    h5: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 20, 
    },
    h6: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 16,
    },
    button:{
      textTransform: 'capitalize',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 16,
    }
  },
  components: {
    MuiButton: {
      styleOverrides:{
        root: {
          '&:hover': {
            color: '#ffffff',
          },
        },
      }
    },
  },
})
const responsiveTheme = responsiveFontSizes(theme);


const App = (props) => {
  return (
    <CookiesProvider>
      <HelmetProvider>
        <SubjectProvider>
          <ThemeProvider theme={responsiveTheme}>
          {/* <ThemeProvider theme={theme}> */}

            <Application />
          </ThemeProvider>
        </SubjectProvider>
      </HelmetProvider>
    </CookiesProvider>
  );
};

export default App;
