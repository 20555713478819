import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import InfoTooltip from "../InfoTooltip";

/**
 * Template that fill the space for the sidebar of the layout.
 * @param {Object} props
 * @param {node} props.children - Children elements to be rendered on the sidebar space.
 * @returns the rendered component
 * @author valeriaxeleva
 */
export const Side = ({
  children,
  open,
  handleDrawerClose,
  handleDrawerOpen,
  location,
  activeGraph,
}) => {
  return (
    <aside id="sidebar" className="side">
      <Typography
        id="sidebar_title"
        variant="h3"
        component="h4"
        color="white.main"
        fontWeight="500"
        sx={{
          padding: "8px 0 16px 0",
        }}
      >
        Filters
        {location === "/graphs" && activeGraph === 0 && (
          <InfoTooltip
          color="white.main"
            tooltipText={
              "Use these filters to see how your investment fund has voted on ESG issues. \nEnter up to 6 investors to compare your fund to others"
            }
          />
        )}
        {location === "/graphs" && activeGraph === 1 && (
          <InfoTooltip
          color="white.main"
            tooltipText={
              "Use these filters to see how your investment fund has voted on ESG issues at specific companies. Enter up to 6 investors to compare your fund to others"
            }
          />
        )}
      </Typography>
      <Button
        onClick={handleDrawerClose}
        sx={{
          position: "absolute",
          top: "14px",
          right: "10px",
          textTransform: "none",
          color: "white.main",
          fontSize: "0.9rem",
        }}
        endIcon={
          <FontAwesomeIcon
            style={{
              fontSize: "30px",
            }}
            icon={faChevronLeft}
          />
        }
      >
        Hide
      </Button>
      {children}
    </aside>
  );
};

export default Side;
