import React from "react";
import { Formik, Form as FormFormik } from "formik";
import * as Yup from "yup";
import {
    FormikTextInput as TextField,
    FormikSelect as Select,
    FormikRadioButton
} from '../utils/forms';
import { Button } from "react-bootstrap";
import Box from '@mui/material/Box';
import {
    updateProposal
} from "../../utils/reqs";

import { useSASBLists } from "../../contexts/subjectsProvider";
/**
 * <p>Modal component for proposal editor</p>
 * <p>Displays a form to edit a selected proposal fromt the datagrid</p>
 * @param {Object} props
 * @param {Object} props.proposalToEdit - object with the data of the proposal to edit
 * @param {Array} props.subjects - sasb list of subjects
 * @param {function} props.setShowModalEditProposal - sets visibility of the modal
 * @param {function} props.setProposalToEdit - sets the proposal to edit
 * @param {function} props.updateDataInCurrentProposals - updates information in datagrid
 * @param {function} props.setLoading - sets loading state
 * @returns Rendered view of modal to edit a proposal
 * @author Medina192
 * @author valeriaxeleva
 */
const ModalEditProposals = ({ proposalToEdit, setShowModalEditProposal,
    setProposalToEdit, updateDataInCurrentProposals, setLoading }) => {

    const { SASBProposalsList } = useSASBLists();
    const subjects = SASBProposalsList;

    const proposalSchema = Yup.object({
        description: Yup.string().required("You must set a description").min(10).max(1500),
        management: Yup.string().required("You must select at least one management vote"),
        proponent: Yup.string().required("You must select at least one proponent")
    });

    const createSubjectObject = (subject) => {
        if (!subject || subject === "default")
            return null;
        let subjectFound = false;
        let parentOfSelectedSubject = "";
        for (const parentSubject of subjects) {
            for (const childSubject of parentSubject.subs) {
                if (subject === childSubject) {
                    subjectFound = true;
                    break;
                }
            }
            if (subjectFound) {
                parentOfSelectedSubject = parentSubject.name;
                break;
            }
        }
        return parentOfSelectedSubject;
    }

    const handleSubmitEditProposal = (values) => {
        setLoading(true);
        let body = JSON.parse(JSON.stringify(values));
        body.meeting_id = proposalToEdit.meetingId;
        body.user = proposalToEdit.user;

        if (proposalToEdit.index !== undefined)
            body.index = proposalToEdit.index;

        if (values.subject !== "none") {
            const parentSubject = createSubjectObject(values.subject);
            if (parentSubject !== null)
                body.subjects = { parent: parentSubject, subject: values.subject };
            else
                delete body.subject;
        } else {
            body.subjects = values.subject;
        }

        updateProposal(body)
            .then((res) => {
                updateDataInCurrentProposals(body);
                alert(res.message)
                resetProposalToEdit();
                setShowModalEditProposal(false)
            }).catch((error) => {
                console.log("error", error)
                alert(error.message)
            }).finally(() => {
                setLoading(false);
            })
    }

    const resetProposalToEdit = () => {
        setProposalToEdit({
            description: "",
            subject: "",
            management: "",
            proponent: ""
        });
    }

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        description: proposalToEdit?.description,
                        subject: proposalToEdit?.subject?.subject ? proposalToEdit?.subject.subject : "",
                        management: proposalToEdit?.management ? proposalToEdit?.management : "",
                        proponent: proposalToEdit?.proponent ? proposalToEdit?.proponent : "",
                    }}
                    validationSchema={proposalSchema}

                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                        handleSubmitEditProposal(values);
                    }}
                >
                    {({ errors, touched, resetForm, setFieldValue }) => (
                        <FormFormik id="editCompanyForm" >
                            <TextField className="w-100"
                                name="description" as="textarea"
                                rows="3"
                                label="Description"
                            />
                            <Select
                                label="Subject"
                                name="subject"
                            >
                                <option value="none" key="none">--None</option>
                                {subjects.map((parentSubject, parentIndex) => {
                                    return (
                                        <>
                                            <option
                                                className="parent-subject-dropdown"
                                                disabled
                                                key={"-" + parentSubject.name.replace(" ","-")}
                                                value={parentSubject.name + "   "}
                                            >{parentSubject.name + "   "}</option>
                                            {
                                                parentSubject.subs.map((childSubject, childIndex) => {
                                                    return (
                                                        <option
                                                            className="child-subject-dropdown"
                                                            key={parentIndex.toString() + "-" + childIndex.toString()}
                                                            value={childSubject}
                                                        >
                                                            {childSubject}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                )}
                            </Select>

                            <div className="d-flex flex-column" style={{ marginTop: "40px" }}>
                                <label style={{ margin: "0px" }}>Management Recommendation:</label>
                                <div className="d-flex justify-content-end w-100">
                                    <div className="d-flex justify-content-between" style={{ width: "65%" }}>
                                        <FormikRadioButton label={"For"} name={"management"} value="for" />
                                        <FormikRadioButton label={"Against"} name={"management"} value="against" />
                                        <FormikRadioButton label={"Other"} name={"management"} value="other" />
                                    </div>
                                </div>
                            </div>
                            <p style={{ color: "#f00", fontSize: "14px" }}>{errors.management && touched.management && errors.management}</p>

                            <div className="d-flex flex-column" style={{ marginTop: "40px" }}>
                                <label>Proponent:</label>
                                <div className="d-flex justify-content-end w-100">
                                    <div className="d-flex justify-content-between" style={{ width: "65%" }}>
                                        <FormikRadioButton label={"Management"} name={"proponent"} value="management" />
                                        <FormikRadioButton label={"Shareholder"} name={"proponent"} value="shareholder" />
                                    </div>
                                </div>
                            </div>
                            <p style={{ color: "#f00", fontSize: "14px" }}>{errors.proponent && touched.proponent && errors.proponent}</p>

                            <div className="d-flex justify-content-end" style={{ marginTop: "30px" }}>
                                <Button type="submit" className="m-2" variant="success">Save Changes</Button>
                                <Button className="m-2" variant="danger" onClick={() => {
                                    resetProposalToEdit();
                                    setShowModalEditProposal(false)
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </FormFormik>
                    )}
                </Formik>
            </Box>
        </>

    )
}

export default ModalEditProposals;