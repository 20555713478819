/**
 * Transform a full Date to format yyyy/mm/dd
 * @param {string} date - full date parsed to string.
 * @returns {string} string with the date
 * @author valeriaxeleva
 */
export function formatDate(date) {
  let newDate = new Date(date);
  return newDate.toISOString().split("T")[0];
}

/**
 * DEPRECATTED. Function to check if user is admin
 * @param {*} cookies
 * @returns
 */
export function isAdmin(cookies) {
  const { session } = cookies;
  if (!session?.roles) return false;
  const { roles } = session;
  return roles.includes("Admin");
}
/**
 * DEPRECATED. Function to check if user is fund
 * @param {*} cookies
 * @returns
 */
export function isFund(cookies) {
  const { session } = cookies;
  if (!session?.user_type) return false;
  const { user_type } = session;
  return user_type === "Asset Manager" || user_type === "Pension";
}
/**
 * DEPRECATED. Function to get the user info
 * @param {*} cookies
 * @returns
 */
export function getUserInfo(cookies) {
  const { session } = cookies;
  const { roles, user_type, name, email } = session;
  return { roles, user_type, name, email };
}

/**
 * Add accesibility tags to HTML elements. This doesn't verify if there is another element with the same values for the attributes.
 * @param {number} index - if element is child of an iterable element, the index of the current element.
 * @param {Object} component - name or small description of the element.
 * @returns object with the proper a11y attributes
 * @author valeriaxeleva
 */
export function a11yProps(index, component) {
  return {
    id: `${component}-${index}`,
    "aria-controls": `${component}-${index}`,
  };
}

export function getCompanyIndexList() {
  return ["S&P 500", "FTSE 100", "S&P/TSX 60", "S&P/ASX 50"];
}

export function filterStatsByFormDates(formOptions,ESGstats=null,allstats=null) {
  if (allstats !== null) {
    let newAllStats = []
    let newESGStats = {}
    for(let stats of allstats) {
      newESGStats = applyFilterByFormDates(formOptions,stats)
      newAllStats.push(newESGStats)
    } 
    return newAllStats
  } 
  return applyFilterByFormDates(formOptions,ESGstats)
}

function applyFilterByFormDates(formOptions,ESGstats) {
  if((formOptions.startDate === null && formOptions.endDate === null) || 
  Object.keys(ESGstats).length === 0 || ESGstats.message) {
    return ESGstats
  }
  let newESGStats = {}
  let dateSequence = [""]
  let endDate = 0
  let startDate = 0
  let sampleArray = []
  if(Object.keys(ESGstats).includes("OG")) sampleArray = ESGstats.OG
  else sampleArray = ESGstats.statistics
  if(formOptions.endDate == null) {
    endDate = Number(sampleArray[sampleArray.length-1].year)
  } else {
    endDate = formOptions.endDate.$d.getFullYear()
  }
  if(formOptions.startDate == null) {
    startDate = Number(sampleArray[0].year)
  } else {
    startDate = formOptions.startDate.$d.getFullYear()
  }
  while (dateSequence[dateSequence.length-1] !== endDate) {
    if(dateSequence[0] === "") {
      dateSequence = [startDate]
    } else {
      dateSequence.push(dateSequence[dateSequence.length-1] + 1)
    }
  }
  const ESGOptions = Object.keys(ESGstats)
  for(let i=0; i < ESGOptions.length; i++) {
    if(ESGOptions[i]==="name") {
      newESGStats[ESGOptions[i]] = ESGstats.name
      continue
    }
    let newStats = []
    for(let j=0; j < dateSequence.length; j++) {
      let currYear = dateSequence[j].toString()
      let ESGelement = ESGstats[ESGOptions[i]].filter((value,_)=>value.year === currYear)
      if(ESGelement.length > 0) {
        newStats.push(...ESGelement)
      } else {
        newStats.push({year:currYear})
      }
    }
    newESGStats[ESGOptions[i]] = newStats
  }
  return newESGStats
}