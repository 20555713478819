import React, { useState } from "react";
import "./css/Login.scss";
import { Button, Form, Alert } from "react-bootstrap";
import { auth } from "../firebase";
import { Helmet } from "react-helmet-async";
import bg from "../assets/bglogin.jpg";

import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setError] = useState(null);

  const onChangeHandler = (event, setValue) => {
    const { value } = event.currentTarget;
    setError(null);
    setValue(value);
  };

  const prettyErrorCode = (code) => {
    let message = code.split("/")[1];
    message = message.replaceAll("-", " ");
    return message;
  };

  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    event.preventDefault();
    //https://firebase.google.com/docs/auth/web/auth-state-persistence[]
    try {
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      window.location.reload();
    } catch (error) {
      console.error(error);
      const { code } = error;
      const message = prettyErrorCode(code);
      setError(message);
    }
  };

  return (
    <div
      className="login-body"
      style={{
        height: "100vh",
        backgroundImage: `url(${bg})`,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, .2)",
        }}
      >
        <Helmet>
          <title>Oxprox | Login</title>
        </Helmet>
        <div className="text-center login-wrapper text-capitalize">
          {errorMsg && (
            <Alert variant="danger" className="mt-3">
              {errorMsg}
            </Alert>
          )}
          <div className="p-3 text-left login-box">
            <Form
              onSubmit={async (event) => {
                await signInWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  placeholder="Enter email"
                  id="userEmail"
                  onChange={(event) => onChangeHandler(event, setEmail)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  placeholder="Enter password"
                  id="userPassword"
                  onChange={(event) => onChangeHandler(event, setPassword)}
                />
              </Form.Group>
              <Button
                style={{
                  border: "none",
                  boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.25)",
                  borderRadius: "50px",
                  backgroundColor: "#002147",
                }}
                block
                type="submit"
              >
                Log in
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
