import api from "../api";

/**
 * <p>Get the user by his id</p>
 * @param {string} id - Mongo Id of the user
 * @returns the user Info
 * @author  Medina192
 */
export const getUserByEmail = async (email) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .getUserByEmail({ email })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Searches companies by starting letter or symbol and saves the data</p>
 * @param {string} key - the starting character
 * @param {number} page - page of the results
 * @param {function} setData - state hook for setting company data
 * @returns a resolved promise that saves the company data if successful or alerts an error if unsuccessful
 * @author rafa
 */
export const getCompanies = async (companyStarting, page) => {
  const response = await api
    .getCompanies(companyStarting, 100, page)
    .then((res) => {
      return res;
    })
    .catch(() => []);
  return response;
  /*
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
    });
    */
};

/**
 * <p>Searches companies by name, ISINs and country</p>
 * @param {string} filters - obligatory paramaters to select the companies
 * @param {number} limit - limit of companies returned
 * @param {number} page - page of the results
 * @returns a resolved promise that saves the company data if successful or alerts an error if unsuccessful
 * @author Medina192
 */
export const searchCompanies = async (page, filters, limit = 100) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .getCompanies(limit, page, filters, filters.names)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Update the company specified with the corresponding fields</p>
 * @param {Object} data - object with the data to update
 * @param {string} data.user - Email of the user who is updating
 * @param {string} data.name - New Name of the company
 * @param {string} data.industry - new industry of the company
 * @param {string} data.sector - new sector of the company
 * @param {string} data.country - new country of the company
 * @param {string[]} data.aliases - array with the aliases of the company
 * @returns {object} an http response json object containing the updated company data
 * @returns {object} an http response json object with the error found
 * @author Medina192
 */
export const updateCompanyFields = async (data) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .updateCompany(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Merge the children companies in the parent company</p>
 * @param {Object} data - object with the data to update
 * @param {string} data.parentId - mongo id fo the parent company
 * @param {object[]} data.children - array of children companies
 * @param {string} data.username - email of the user who is merging the companies
 * @returns {object} a message that says: merge succesful
 * @returns {object} an http response json object with the error found
 * @author Medina192
 */
export const mergeCompanies = async (data) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .mergeCompanies(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * get found logs based in parameters
 * @param {Object} data - query object
 * @param {string} data.name - name of the log we want to find
 * @param {string} data.operation - type of operation (edit or merge)
 * @param {string} data.modifiedBy - what user create this log
 * @param {string} data.modifiedStart - minimum date of creation that should have the log
 * @param {string} data.modifiedEnd - maximum date of creation that should have the log
 * @param {string} data.skip - number of document in which will start the search
 * @param {string} data.limit - limit of documents that will bring
 * @param {string} data.isPrev - parameter to search the data in the previous or subsequent merge data
 * @returns {Object} an http response json object containing the fund logs found
 * @returns an http response json object with the error found
 * @author Medina192
 */
export const getFoundLogs = async (data) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .getFoundLogs(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Get a fund by its id</p>
 * @param {Object} id - id of the fund to be searched
 * @returns a the fund found
 * @author  Medina192
 */
export const getFundbyId = async (id) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .getFundbyId(id)
      .then((res) => {
        resolve(res.data.fund);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Get funds by their id</p>
 * @param {Object} arrayOfIds - array of ids of the funds to be searched
 * @returns a the funds found
 * @author  Medina192
 */
export const getFundsbyId = async (arrayOfIds) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .getFundsbyId({ _id: arrayOfIds })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Merge the funds into one</p>
 * @param {Object} data - body of the request
 * @param {Object} data.selectedFund - fund that will remain
 * @param {Object} data.fundsToMerge - funds that will be remove
 * @param {Object} data.username - email of the user who is merging
 * @returns the fund merged
 * @author  Medina192
 */
export const mergeFunds = async (data) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .mergeFunds(data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Searches for all the funds grouped by its parents</p>
 * @param {Object} data - query object
 * @param {string} data.type - type of found (investment or pension)
 * @param {string} data.country - country of the found
 * @param {string} data.fundStarting - letter with the name of the fund begins
 * @returns an array of grouped funds if successful or an empty array i.o.c
 * @author rafa, Medina192
 */
export const getGroupedFunds = async (filters = {}) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .getGroupedFunds(filters)
      .then((res) => {
        resolve(res.data.funds);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * Function that will send filters to api endpoint to query funds documents.
 *
 * @see main Req-1.1
 * @async
 * @param {object} params - filter params the endpoint will get to return data
 * @param {int} limit - limit of documents returned
 * @param {int} page
 * @param {function} setData - function to set the component data state
 * @author sebasRM
 */

export async function getFundsBy(params, limit, page, sort) {
  const response = await api
    .getFundsBy(params, limit, page, sort)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
}

/**
 * Function that will send filters to api endpoint to query funds documents.
 *
 * @see main Req 1.2
 * @async
 * @param {object} data - filter params the endpoint will get to update fund
 * @returns {JSONObject} - JSON Object with response  status and message
 * @author sebasRM
 */
export async function updateFund(data) {
  const response = await api
    .updateFund(data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
}

/**
 * <p>Searches for paginated meeting data</p>
 * @param {Object} body - body of the request containing filters for the meetings
 * @returns an object containing meeting data and pagination if successful, or an object containing the string error i.o.c
 * @author rafa
 */
export const getProposals = async (body) => {
  const response = await api
    .findProposals(body)
    .then((res) => {
      const { proposals, message, ...pagination } = res.data;
      return { proposals, ...pagination };
    })
    .catch((error) => {
      const { response } = error;
      const msg = !response?.data ? error.message : response.data.error;
      return { error: msg };
    });
  return response;
};

/**
 * <p>Update proposal fields</p>
 * @param {string} req.body.meetingId id of the meeting to search the proposal
 * @param {string} req.body.oldDescription description to search the proposal
 * @param {string} req.body.newDescription new description
 * @param {string} req.body.subject new subject
 * @param {string} req.body.management new management
 * @param {string} req.body.proponent new proponent
 * @returns {Object} a success message
 * @author Medina192
 */
export const updateProposal = async (body) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .updateProposal(body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Merge the proposals sent in one</p>
 * @param {string} req.param.meetingId id of the meeting to search the proposal
 * @param {string} req.body.indexesChildren id of the meeting to search the proposal
 * @param {string} req.body.indexParent id of the meeting to search the proposal
 * @param {string} req.body.user id of the meeting to search the proposal
 * @returns {Object} a success message
 * @author Medina192
 */
export const mergeProposal = async (body) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .mergeProposal(body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * <p>Updates the subjects for each proposal of the list</p>
 * @param {Object} proposals - array of proposals
 * @returns a resolved promise that alerts if there was an error
 * @author rafa
 */
export const updateSubjects = async (proposals, user) => {
  await api
    .updateProposalSubjects({ proposals, user })
    .then((response) => {
      const { proposals } = response.data;
      const notUpdated = proposals.filter((proposal) => !proposal.updated);
      if (notUpdated.length > 0) {
        const reasons = [
          ...new Set(notUpdated.map((proposal) => proposal.reason)),
        ];
        alert(`Some proposals couldn't be updated. Reasons below:\n${reasons}`);
      }
    })
    .catch((err) => {
      const { response } = err;
      alert(!response?.data ? err.message : response.data.message);
    });
};

export const uploadFiles = async (files) => {
  await api
    .uploadFiles(files)
    .then((res) => alert(res.data.message))
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
    });
};

/**
 * <p>Searches for ESG subjects information to be shown in Insights page </p>
 * no params needed
 * @returns an array of subjects if successful or an empty array i.o.c
 * @author milenexeleva
 */
export const getESGInfo = async (subject) => {
  const response = await api
    .getESGInfo(subject)
    .then((res) => {
      const { insights } = res.data;
      return insights;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
    });
  return response;
};

/**
 * <p>Searches for sasb subject listings</p>
 * @param {string} type - type of subject
 * @returns an array of subjects if successful or an empty array i.o.c
 * @author rafa
 */
export const getSASBLists = async (type) => {
  const response = await api
    .getSASBlists(type)
    .then((res) => {
      const { subjects } = res.data;
      return subjects;
    })
    .catch(() => []);
  return response;
};

export const getUploadLogs = async (page, params, limit = 100) => {
  const response = await api
    .getUploadLogs(params, limit, page)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
};

/**
 * Function that will retrieve logs from the logs collection that correspond to companies.
 *
 * @see main Req 8.7
 * @async
 * @param {number} limit - limit of the results for search.
 * @param {number} page - page to search.
 * @param {object} params - values to filter logs.
 * @returns {array} - List of matching results as objects.
 * @author palemona
 */
export async function getCompaniesLogs(page, params, limit = 100) {
  const response = await api
    .searchCompanyLogs(params, limit, page)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
}

/**
 * Function that will retrieve logs from the logs collection that correspond to proposals.
 *
 * @see main Req 2.6 (3rd IT)
 * @async
 * @param {number} limit - limit of the results for search.
 * @param {number} page - page to search.
 * @param {object} filters - values to filter logs.
 * @returns {array} - List of matching results as objects.
 * @author valeriaxeleva
 */
export async function getProposalLogs(filters) {
  const response = await api
    .searchProposalLogs(filters)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
}

/**
 * Function that will send filters to api endpoint to generate proposals CSV.
 *
 * @see main Req 9.2
 * @async
 * @param {object} params - filter params the endpoint will get to generate CSV
 * @returns {Blob} - Blob with csv file to download
 * @author sebasRM
 */
export async function generateCsv(params) {
  const response = await api
    .generateCsv(params)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return {};
    });
  return response;
}

/**
    * <p>Get the statistics by total of votes and their percentage</p>
    * @param {string} filters.fund - Mongo Id from the fund
    * @param {string} filters.company - Mongo Id from the company
    * @param {string} filters.startDate - search start date in format yyyy/mm/dd
    * @param {string} filters.endDate - search end date in format yyyy/mm/dd
    * @param {string} filters.financiallyMaterial - string that can be 'true' or empty, parameter to match in the pipeline
    * @param {string[]} filters.subjects - subjects to search in the proposals
    * @param {string[]} filters.keywords - keywords to search coincidences in the proposal's description
    * @returns the totalVotes found(integer) and an array of objects with the following structure
    {
        "year": "2019",
        "forPercentage": 0.8417537746806039,
        "forTotal": 5798,
        "againstPercentage": 0.08405923344947736,
        "againstTotal": 579,
        "otherPercentage": 0.0741869918699187,
        "otherTotal": 511
    }
    * @author Medina192, Palemona
*/
export const getVoteProportion = async (filters = {}) => {
  const response = await api
    .getVoteProportion(filters)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
};

/**
 * <p>Get the statistics by total of votes and their percentage</p>
 * @param {string} filters.fund - Mongo Id from the fund
 * @param {string} filters.company - Mongo Id from the company
 * @param {string} filters.startDate - search start date in format yyyy/mm/dd
 * @param {string} filters.endDate - search end date in format yyyy/mm/dd
 * @param {string} filters.financiallyMaterial - string that can be 'true' or empty, parameter to match in the pipeline
 * @param {string[]} filters.subjects - subjects to search in the proposals
 * @param {string[]} filters.keywords - keywords to search coincidences in the proposal's description
 * @returns a csv file with the proposals
 * @author Medina192
 */
export const getVoteProportionCsv = async (filters = {}) => {
  const response = await api
    .getVoteProportionCsv(filters)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
};

/**
 * <p>Get the proposals of the votes</p>
 * @param {string} filters.fund - Mongo Id from the fund
 * @param {string} filters.company - Mongo Id from the company
 * @param {string} filters.startDate - search start date in format yyyy/mm/dd
 * @param {string} filters.endDate - search end date in format yyyy/mm/dd
 * @param {string} filters.financiallyMaterial - string that can be 'true' or empty, parameter to match in the pipeline
 * @param {string[]} filters.subjects - subjects to search in the proposals
 * @param {string[]} filters.keywords - keywords to search coincidences in the proposal's description
 * @returns a csv file with the proposals
 * @author Medina192
 */
export const getVoteProportionAll = async (filters = {}) => {
  const response = await api
    .getVoteProportionAll(filters)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      console.log(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
};

/**
 * <p>Create a new User</p>
 * @param {string} body.name - Name of the new user
 * @param {string} body.email - email of the new user
 * @param {string} body.password - password of the new user
 * @param {string} body.roles - roles of the new user
 * @param {string} body.user_type - user_type of the new user
 * @returns the user Info
 * @author  Medina192
 */
export const createUser = async (body) => {
  const promise = new Promise(function (resolve, reject) {
    api
      .createUser(body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
  return promise;
};

/**
 * Get ESG stats from fund, retrieved in the statistics collection
 * @param {string} body.id - mongo Object_id of the fund
 * @returns {Object} - the EST stats from the fund
 * @author  valeriaxeleva
 */
export const getESGFundStats = async (body) => {
  const response = await api
    .getESGFundStats(body)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const { response } = error;
      alert(!response?.data ? error.message : response.data.message);
      return [];
    });
  return response;
};


/**
 * The function `sendEmail` sends an email using an API and returns the response.
 * @param body - The `body` parameter in the `sendEmail` function likely contains the necessary
 * information for sending an email, such as the recipient's email address, subject, message content,
 * attachments, etc. It is the data that will be used to compose and send the email through the
 * `api.sendEmail`
 * @returns The `sendEmail` function is returning the response from the `api.sendEmail` call. If the
 * call is successful, it will return the response data. If there is an error, it will return an empty
 * array `[]`.
 */
export const sendEmail = async (body) => {
  const response = await api
    .sendEmail(body)
    .then((res) => {
      return res;
    })
    .catch(() => []);;
  return response;
};
