import { HomeMainContent } from "./Layout/HomeMainContent";
import "./css/HomePages.scss";
import logo from "../assets/Logo.png";
import oxford from "../assets/OXFORD LOGOS.png";
import { ReactComponent as EarthIcon } from "../assets/earth.svg";
import { ReactComponent as MultipleIcon } from "../assets/multiple-users-silhouette.svg";
import { ReactComponent as NounBoardroomIcon } from "../assets/noun-boardroom.svg";
// import { Box, Button, Divider, Typography } from "@mui/material";
import { Box, Button, Grid, Typography } from "@mui/material";
// import Diversity2Icon from "@mui/icons-material/Diversity2";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { MdEco } from "react-icons/md";
import HomePage from "./Layout/HomePage";
import { styled } from "@mui/styles";
// import { useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ESGContent from "./Insights/ESGContent";
// import ContactForm from "./ContactForm/ContactForm";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";

const FooterInformation = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.blue.deepDark,
  padding: "30px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    padding: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
  },
}));

// const HeadLine = styled(Divider)(({ theme }) => ({
//   backgroundColor: "#eef0ff",
//   width: "100%",
//   position: "absolute",
//   top: "65px",
//   fontSize: "24px",
//   overflow: "hidden",
//   zIndex: "0",
//   "& .MuiDivider-wrapper": {
//     padding: "0",
//   },
// }));

// const NextSectionButton = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   fontSize: "30px",
//   left: { xs: "25%", md: "45%" },
//   bottom: "16px",
//   "& button": {
//     textTransform: "capitalize",
//     border: "3px solid #eef0ff",
//     fontSize: "16px",
//     padding: "1px",
//     width: "180px",
//     borderRadius: "50px",
//     marginBottom: "6px",
//   },
// }));

const IconContainer = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18rem",
  height: "18rem",
  [theme.breakpoints.down("lg")]: {
    width: "14rem",
    height: "14rem",
  },
  [theme.breakpoints.down("md")]: {
    width: "14rem",
    height: "14rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10rem",
    height: "10rem",
  },
  [theme.breakpoints.down("xs")]: {
    width: "10rem",
    height: "10rem",
  },
}));

const IconSVGSize = {
  width: {
    xs: "10rem",
    sm: "10rem",
    md: "11rem",
    lg: "12rem",
  },
  height: {
    xs: "10rem",
    sm: "10rem",
    md: "11rem",
    lg: "12rem",
  },
};
/**
 * <p>Main component for Insights page</p>
 * <p>Displays information about votes and proposals from the database separated by ESG subjects. </p>
 * <p>Each section has links to redirect to statistics or database searches related to each subject</p>
 * @param {Object} props
 * @returns rendered view of Insights component
 * @author milenexeleva
 */

const Insights = () => {
  const theme = useTheme();
  // const search = useLocation().search;
  // const searchParams = new URLSearchParams(search);
  // const section = searchParams.get("section");
  // const ContactSectionRef = useRef(null);
  // const EnvSectionRef = useRef(null);

  // useEffect(() => {
  //   if (section === "contact" || section === "contact-us") {
  //     scrollToContact();
  //   }
  // }, [section]);

  // const scrollToContact = () => {
  //   window.scrollTo({
  //     top: ContactSectionRef.current.offsetTop,
  //     behavior: "smooth",
  //   });
  // };
  // const goToEnvironment = () => {
  //   window.scrollTo({
  //     top: EnvSectionRef.current.offsetTop - 40,
  //     behavior: "smooth",
  //   });
  // };

  const ESGInfo = [
    {
      image: "patternNavy",
      title: "Environment",
      content: `Includes proposals related to themes such as Greenhouse 
            Gas Emissions, Air Quality, Energy Management, Water and 
            Wastewater Management, Waste and Hazardous Material 
            Management, and Other Ecological Impacts.`,
      icon: (
        <IconContainer bgcolor="none">
          {" "}
          <SvgIcon
            component={EarthIcon}
            viewBox="0 0 48.625 48.625"
            sx={{
              width: {
                xs: "10rem",
                sm: "10rem",
                md: "14rem",
                lg: "18rem",
              },
              height: {
                xs: "10rem",
                sm: "10rem",
                md: "14rem",
                lg: "18rem",
              },
              color: "gray.light",
            }}
          />{" "}
        </IconContainer>
      ),
    },
    {
      image: "patternBeige",
      title: "Social",
      content: `Includes proposals related to themes such as Labor Practices, 
      Human Rights, Diversity and Inclusion, Health and Safety, 
      Community Engagement, and Product Safety.`,
      icon: (
        <IconContainer bgcolor="primary.main">
          <SvgIcon
            component={MultipleIcon}
            viewBox="0 0 80.13 80.13"
            sx={{
              ...IconSVGSize,
              color: "gray.light",
            }}
          />
        </IconContainer>
      ),
    },
    {
      image: "patternRed",
      title: "Governance",
      content: `Includes the systems, processes, and structures that guide 
      corporate decision-making and accountability. It involves 
      Board Composition, Executive Compensation, Shareholder 
      Rights, Transparency, and Ethical Conduct.`,
      icon: (
        <IconContainer bgcolor="gray.light">
          <SvgIcon
            component={NounBoardroomIcon}
            viewBox="0 0 100 100"
            sx={{
              ...IconSVGSize,
              color: "red.dark",
            }}
          />
        </IconContainer>
      ),
    },
  ];

  return (
    <HomePage>
      <HomeMainContent title="Insights">
        <HomeMainContent.Body>
          <Box
            sx={{
              height: "100%",
              color: "primary",
            }}
          >
            <Grid container className="insight-intro content-box-heroa">
              <Grid item xs={12} md={6}>
                <Box
                  className="insight-intro-div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent:"flex-start",
                    height: "100%",
                    padding: {
                      xs: "2rem 2rem",
                      sm: "3rem 4rem",
                      md: "3rem 5rem",
                      lg: "4rem 6rem",
                      xl: "4rem 7rem",
                    },
                  }}
                >
                  <Typography
                    id="article_titles"
                    variant="h1"
                    component="h2"
                    color={"primary.main"}
                    sx={{
                      fontFamily: "Poppins",
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "3.375rem",
                      },
                      mb: "2rem"
                    }}
                  >
                    Graphs
                  </Typography>
                  <Typography
                    variant="h5"
                    component="p"
                    sx={{
                      fontWeight: 500,
                      mb:  "2.5rem"
                    }}
                    color={"primary.main"}
                  >
                    See a visual representation of how institutional investors
                    have voted on environmental, social, and governance (ESG)
                    proposals at an aggregate level over time.
                  </Typography>

                  <Button
                    href="/graphs"
                    color="secondary"
                    variant="contained"
                    className="btn-general"
                    sx={{
                      maxWidth: {sm:"70%", md: "50%"}
                    }}
                  >
                    View Graphs
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="insight-intro-div content-box patternNavy"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    padding: {
                      xs: "2rem 2rem",
                      sm: "3rem 4rem",
                      md: "3rem 5rem",
                      lg: "4rem 6rem",
                      xl: "4rem 7rem",
                    },
                  }}
                >
                  <Typography
                    id="article_titles"
                    variant="h1"
                    component="h2"
                    color={"white.main"}
                    sx={{
                      fontFamily: "Poppins",
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "3.375rem",
                      },
                      mb: "2rem"
                    }}
                  >
                    Voting Records
                  </Typography>
                  <Typography
                    variant="h5"
                    component="p"
                    sx={{
                      fontWeight: 500,
                      mb: "2.5rem"
                    }}
                  >
                    Search for investors´ proxy voting records to se how they´ve
                    voted on proposals at a particular company, or within a
                    particulary industry, or on particulary ESG issues.
                  </Typography>
                  <Button
                    className="btn-general"
                    href="/voting-records"
                    color="secondary"
                    variant="contained"
                    sx={{
                      mb: {
                        xs: "1rem",
                        sm: "0",
                      },
                    maxWidth: {sm:"70%", md: "50%"},

                    }}
                  >
                    Search Votes
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {/* className=" content-box-hero insight-intro" */}
            <Box className="content-box patternRed">
              <Box
                className="img-shadow"
                sx={{
                  padding: {
                    xs: "2rem 2rem",
                    sm: "3rem 4rem",
                    md: "3rem 5rem",
                    lg: "4rem 6rem",
                    xl: "4rem 7rem",
                  },
                }}
              >
                <Typography
                  variant="h3"
                  component="p"
                  sx={{
                    fontWeight: 400,
                  }}
                  align="center"
                >
                  The OxProx database enables everyone to easily access,
                  analyze, and compare the proxy voting records of institutional
                  investors in one convenient location.
                </Typography>
              </Box>
            </Box>
            {/* <Divider
              ref={EnvSectionRef}
              sx={{
                color: "transparent",
                border: "none",
                backgroundColor: "transparent",
              }}
            ></Divider> */}
            {ESGInfo?.map((Info, index) => {
              return (
                <ESGContent
                  index={index}
                  key={`${Info.title}-${index}`}
                  image={Info.image}
                  title={Info.title}
                  content={Info.content}
                  icon={Info.icon}
                />
              );
            })}
            {/* <Box
              sx={{
                display: "flex",
                paddingTop: "70px",
                width: "100%",
                justifyContent: "space-between",
              }}
              ref={ContactSectionRef}
            >
              <Box className="imageContact"></Box>
              <Box className="takePart">
                <Typography component="h2">
                  Take part on the big decisions
                </Typography>
                <Typography id="article_content" className="invertedColor">
                  Are you a company looking to streamline your voting records?
                  Are you asset owner or asset manager? Are you interested in
                  shareholder advocacy? <br />
                  Take part on the big decisions by sending us a message and we
                  will reply as soon as possible.
                </Typography>
              <ContactForm/>
              </Box>
            </Box> */}
            <FooterInformation>
              <Box display="flex" alignItems="center">
                <Box
                  component="img"
                  alt="Oxprox Logo"
                  src={logo}
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100px",
                    display: "block",
                  }}
                />
                <Typography
                  sx={{
                    maxWidth: { xs: "120px", md: "100%" },
                    margin: { xs: "0 20px", md: "0 40px" },
                    padding: {
                      xs: "1rem",
                      sm: "2rem 2rem",
                      md: "40px 0 40px 40px",
                    },
                    borderLeft: `2px solid ${theme.palette.white.main}`,
                  }}
                  component="p"
                >
                  Amplifying Investors Stewardship
                </Typography>
              </Box>
              <Box
                component="img"
                alt="Oxford Logos"
                src={oxford}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "200px",
                  display: "block",
                }}
              />
            </FooterInformation>
          </Box>
        </HomeMainContent.Body>
      </HomeMainContent>
    </HomePage>
  );
};

export default Insights;
