// import { faCircleRight } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { getESGInfo } from "../../utils/reqs";
import Grid from "@mui/system/Unstable_Grid";
import { useTheme } from "@mui/material/styles";
// import Container from '@mui/material/Container';

const InfoBoxes = styled(Box)(({ theme, image }) => ({
  minWidth: "160px",
  padding: "1rem",
  borderRadius: "28px",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color:
    image === "patternBeige"
      ? theme.palette.primary.main
      : theme.palette.white.main,
  "&.MuiBox-root": {
    border:
      image === "patternBeige"
        ? `6px solid ${theme.palette.primary.main}`
        : `6px solid ${theme.palette.white.main}`,

    // [theme.breakpoints.up("md")]: {
    //   border:
    //     image === "patternBeige"
    //       ? `6px solid ${theme.palette.primary.main}`
    //       : `6px solid ${theme.palette.white.main}`,
    // },
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5rem",
    },
  },
}));

const PageDivision = styled(Box)(({ theme }) => ({
  border: "none",
  textAlign: "initial",
  boxShadow: "none",
  whiteSpace: "normal",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
}));

/**
 * Component that renders each subject section of Insights page
 * @param {Object} props
 * @param {string} props.image - Css class in which the respective background image was defined
 * @param {string} props.title - Subject name to be displayed as title
 * @param {string} props.content - Description to be shown below the subject name
 * @param {Object} props.icon - Icon component to be rendered alongside the subject name
 * @returns the rendered component
 * @author milenexeleva
 */
const ESGContent = ({ image, title, content, icon }) => {
  const theme = useTheme();
  const [statsData, setStatsData] = useState({
    total: "...",
    subjectGroup: "",
    percentageApproved: "...",
    topInvestors: [],
    topCompany: null,
    mostVoted: [],
    keywords: ["...", "...", "..."],
  });

  useEffect(() => {
    const executeReq = async () => {
      const subjectInfo = await getESGInfo(title[0]);
      if (subjectInfo) {
        setStatsData((prevStatsData) => ({
          ...prevStatsData,
          total: subjectInfo.total,
          subjectGroup: subjectInfo.subjectGroup,
          percentageApproved: Math.round(subjectInfo.percentageApproved),
          topInvestors: [...subjectInfo.topInvestors],
          topCompany: subjectInfo.topCompany,
          mostVoted: [...subjectInfo.mostVoted],

          keywords: subjectInfo.keywords.map(capitalizeFirstLetter),
        }));
      }
    };
    if (title) {
      executeReq();
    }
  }, [title]);

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const formatNumber = (number) => {
    if (number) {
      let str = number.toString();
      if (str.length > 6) {
        return (
          str.slice(0, str.length - 6) +
          "," +
          str.slice(str.length - 6, str.length - 3) +
          "," +
          str.slice(str.length - 3)
        );
      }
      if (str.length > 3) {
        return str.slice(0, str.length - 3) + "," + str.slice(str.length - 3);
      }
      return str;
    }
  };

  return (
    <Box className={image === "patternBeige" ? "content-box" : `content-box ${image}`}
    
    bgcolor={image === "patternBeige" && "gray.light"}
    >
      <Box
      // sx={{ position: { xs: "relative" } }}
      >
        <Grid
          container
          sx={{
            padding: {
              xs: "2rem 2rem",
              sm: "3rem 4rem",
              md: "4rem 5rem",
              lg: "5rem 6rem",
              xl: "6rem 7rem",
            },
          }}
        >
          <Grid xs={12} md={8}>
            <PageDivision
            // sx={{
            // maxWidth: { xs: "100%", md: "70%" },
            // padding: { xs: "30px", md: "40px 80px" },
            // color: "red",
            // }}
            >
              <Typography
                id="ESG_titles"
                variant="h1"
                component="h2"
                mb={2}
                color={image === "patternBeige" ? "primary.main" : "white.main"}
                sx={{
                  [theme.breakpoints.up("lg")]: {
                    fontSize: "3.375rem",
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                id="article_content"
                sx={{ maxWidth: { md: "90%" }, fontWeight: "500" }}
                color={image === "patternBeige" ? "primary.main" : "white.main"}
                component="p"
              >
                {content}
              </Typography>
              <Box
                sx={{
                  display: { md: "flex" },
                  marginTop: { xs: "10px", md: "60px" },
                  gap: "5%",
                  width: "100%",
                }}
              >
                <InfoBoxes className="highlights" image={image}>
                  <Typography
                    component="p"
                    variant="h1"
                    align="center"
                    sx={{
                      fontFamily: "Poppins",
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "3.375rem",
                      },
                    }}
                  >
                    {formatNumber(statsData.total)}
                  </Typography>
                  <Typography
                    id="article_content_box"
                    sx={{
                      marginTop: { xs: "5px", md: "20px" },
                      fontWeight: "500",
                    }}
                    component="p"
                  >
                    Proposals in this category or our database were voted on
                    during the 2022-2023 proxy period.
                  </Typography>
                </InfoBoxes>
                <InfoBoxes className="highlights" image={image}>
                  <Typography
                    component="p"
                    variant="h1"
                    align="center"
                    sx={{
                      fontFamily: "Poppins",
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "3.375rem",
                      },
                    }}
                  >
                    {statsData.percentageApproved + "%"}
                  </Typography>
                  <Typography
                    id="article_content_box"
                    sx={{
                      marginTop: { xs: "5px", md: "20px" },
                      fontWeight: "500",
                    }}
                    component="p"
                  >
                    Of proposals in our database with this category were voted
                    in favor in 2022-2023 proxy period.
                  </Typography>
                </InfoBoxes>
                <InfoBoxes className="highlights" image={image}>
                  <Typography
                    fontWeight="fontWeightBold"
                    id="article_content_box"
                    sx={{ margin: "0", textAlign: { xs: "center" } }}
                    component="p"
                  >
                    Most popular keywords in this category during the 2022-2023
                    proxy period:
                  </Typography>
                  <Typography
                    id="article_content_box"
                    sx={{
                      margin: "0",
                      textAlign: { xs: "center" },
                      fontWeight: "500",
                    }}
                    component="p"
                  >
                    {statsData.keywords?.map((k, index) => (
                      <>
                        <br />
                        {`${index + 1}. ${k}`}
                      </>
                    ))}
                  </Typography>
                </InfoBoxes>
              </Box>
              {/* <Box
            sx={{
              height: { md: "180px" },
              paddingTop: { xs: "16px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="article_content_link"
              component="a"
              href={`/voting-records?search=${statsData.subjectGroup}`}
              sx={{
                fontWeight: "700",
                margin: { md: "30px 0 10px 0" },
              }}
            >
              Explore on database <FontAwesomeIcon icon={faCircleRight} />
              <br />
            </Typography>
            <Typography
              id="article_content_link"
              component="a"
              href={`/graphs?search=investors-${statsData.subjectGroup}`}
            >
              Top investors on proposals regarding this subject{" "}
              <FontAwesomeIcon icon={faCircleRight} />
              <br />
            </Typography>
            <Typography
              id="article_content_link"
              component="a"
              href={`/voting-records?search=company-${statsData.subjectGroup}`}
            >
              The company with most proposals on this subject{" "}
              <FontAwesomeIcon icon={faCircleRight} />
              <br />
            </Typography>
            <Typography
              id="article_content_link"
              component="a"
              href={`/voting-records?search=proposals-${statsData.subjectGroup}`}
            >
              Most voted proposals on this subject{" "}
              <FontAwesomeIcon icon={faCircleRight} />
            </Typography>
          </Box> */}
            </PageDivision>
          </Grid>
          <Grid
            xs={12}
            md={4}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ display: { md: "grid", sm: "none", xs: "none" } }}
          >
            {icon}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ESGContent;
