import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Dropdown } from "react-bootstrap";
import renderCellExpand from "../utils/renderCellExpand";
import { EditCompanyForm } from "./EditCompanyForm";
import { EditTable } from "../Layout/EditTable";
import { updateCompanyFields, mergeCompanies } from "../../utils/reqs";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BaseModal } from "../Layout/BaseModal";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import SpinnerFull from "../utils/SpinnerFull";

/**
 * Table to display fetched data using MUI components with server pagination, based on the Editor + Logs OxProx pattern.
 * @param {Object} props
 * @param {Object[]} props.rows - The rows of the data to be displayed
 * @param {number} props.rowsCount - The total number of rows.
 * @param {function} props.setRows - Function to change the state of the rows hook.
 * @param {boolean} props.loadingTable - True if the data is still fetching, false i.o.c.
 * @param {boolean} props.showLogsTable - True if the visible view is the Logs one, false if is the Editor view. Note the columns, rows and searchFunction will change according of this value.
 * @param {function} props.searchFunction - The function that will search the data if the user changes the page.
 * @param {Object} props.formikMainSearch - The body query that will be passed to the searchFunction
 * @param {Object} ref - a reference to the component
 * @returns the rendered component
 * @author valeriaxeleva
 */
const CompaniesTable = (
  {
    rows,
    rowsCount,
    setRows,
    showLogsTable,
    loadingTable,
    searchFunction,
    formikMainSearch,
  },
  ref
) => {
  const [user] = useAuthState(auth);
  const username = user?.email;
  const currentEditCompanyRef = useRef({});
  const [currentEditCompany, setCurrentEditCompany] = useState({
    name: "",
    country: "",
    sector: "",
    subsector: "",
    industry: "",
    ISINs: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [errorModalMessageIsin, setErrorModalMessageIsin] = useState({
    show: false,
    msg: "",
  });
  const [remainOptions, setRemainOptions] = useState([]);
  const [remainCompany, setRemainCompany] = useState(undefined);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const getStringOfArray = (array) => {
    let result = "";
    if (array?.length > 0) {
      for (let i = 0; i < array.length; i++) {
        result += array[i];
        if (i < array.length - 1) {
          result += ", ";
        }
      }
    }
    return result;
  };
  const columnsCompanies = [
    {
      field: "name",
      headerName: "Name",
      flex: 5,
      renderCell: renderCellExpand,
    },
    { field: "country", headerName: "Country", flex: 3 },
    {
      field: "industry",
      headerName: "Industry",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "sector",
      headerName: "Sector",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "subsector",
      headerName: "Subsector",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "ISINs",
      headerName: "ISIN",
      flex: 3,
      valueGetter: ({ row }) => getStringOfArray(row.ISINs),
      valueSetter: ({ row }) => getStringOfArray(row.ISINs),
      renderCell: renderCellExpand,
    },
    {
      field: "_id",
      headerName: "Action",
      flex: 3,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              openEditModal(params.row);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const columnsLogs = [
    {
      field: "company",
      headerName: "Document",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "createdAt",
      headerName: "Modified",
      flex: 3,
      renderCell: ({ value, colDef }) =>
        renderCellExpand({ value: value, colDef }),
    },
    {
      field: "prevData",
      headerName: "Prev Data",
      flex: 4,
      renderCell: ({ value, colDef }) => {
        let result = [];
        let count = 0;
        Object.entries(value).forEach(([key, values]) => {
          const num = Number(key);
          if (Number.isInteger(num) && value.length !== undefined) {
            /*without condition, the companies will repeat many times*/
            if (!count) {
              for (const comp of value) {
                result.push(" name: " + comp.name);
                let aliases = "";
                for (const alias of comp.aliases) {
                  aliases += alias;
                }
                result.push(
                  " aliases: " + (aliases.length > 0 ? aliases : "none")
                );
              }
            }
            count++;
            if (count === value.length) count = 0;
          } else result.push(key + " : " + values);
        });
        return renderCellExpand({ value: result.join(" , "), colDef });
      },
    },
    {
      field: "newData",
      headerName: "New Data",
      flex: 4,
      renderCell: ({ value, colDef }) => {
        let result = [];
        Object.entries(value).forEach(([key, values]) => {
          result.push(key + " : " + values);
        });
        return renderCellExpand({ value: result.join(" , "), colDef });
      },
    },
  ];

  /**
   * <p>Verify defualt fields and does the request to edit the company</p>
   * @param {Object} values - values sent by the formik
   * @param {string} values.name - new name of the company, (or the same if the filed did not modified)
   * @param {string} values.sector - new sector of the company, (or the same if the filed did not modified)
   * @param {string} values.industry - new industry of the company, (or the same if the filed did not modified)
   * @param {string} values.country - new  country of the company, (or the same if the filed did not modified)
   * @returns void, it resolves a promise internally
   * @author Medina192
   */
  const editCompany = async (values) => {
    values.user = username;
    if (values.subsector === "default") values.subsector = "";
    if (values.sector === "default") values.sector = "";
    if (values.industry === "default") values.industry = "";
    if (values.country === "default") values.country = "";

    currentEditCompanyRef.current = values;
    setLoading(true);
    updateCompanyFields(values)
      .then((res) => {
        updateTable();
        setCurrentEditCompany({});
        setShowModal(false);
        alert("The company was saved succesfully");
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally((_) => setLoading(false));
  };

  /**
   * <p>Open the edit modal, verify and set the initial values of the company to edit</p>
   * @param {Object} company - values sent by the formik
   * @author Medina192
   */
  const openEditModal = (company) => {
    setCurrentEditCompany(company);
    setShowModal(true);
  };

  const handleMergeModal = () => setShowMergeModal(false);

  /**
   * [DEPRECATED] Validates if the companies can be merged via their isins. REVIEW
   * @param {string} parent - parent company to stay
   * @param {string[]} children - array of companies to be merged to the parent
   * @author Medina192
   */
  const verifyIsinConstraints = (parent, children) => {
    if (children.length > 4)
      return { show: true, msg: "You cannot merge more than 5 companies" };

    if (parent.SICS_code) {
      for (const child of children) {
        if (child.SICS_code) {
          if (parent.SICS_code !== child.SICS_code)
            return {
              show: true,
              msg: "A child company does not have the same ISIN as its parent",
            };
        }
      }
      return { show: false };
    } else {
      for (const child of children) {
        if (child.SICS_code) {
          return {
            show: true,
            msg: "A child company cannot have ISIN if the parent does not have it",
          };
        }
      }
      return { show: false };
    }
  };

  /*ANCHOR MERGE COMPANIES FUNCTION*/
  /**
   * Merges the child companies to the parent company. This values are storaged in hooks.
   * @author Alexis
   * @author Medina192
   */
  const handleMerge = () => {
    if (!remainCompany) {
      alert("You must select the company that will stay!");
      return;
    }

    // delete parent from selected items
    const childrenIds = selectionModel.filter((id) => id !== remainCompany._id);
    const children = [];

    //example of for-of loop

    for (const row of rows) {
      if (childrenIds.includes(row._id)) {
        children.push({
          id: {$oid:row._id},
          //ISINs: row.ISINs,
        });
      }
    }

    const parent = {
      id: {$oid:remainCompany._id},
      //ISINs: remainCompany.ISINs,
    };

    if (children.length > 4) {
      setErrorModalMessageIsin({
        show: true,
        msg: "You cannot merge more than 5 companies",
      });
      return;
    }

    // save aliases merged in the current state
    let listAllAliases = remainCompany.aliases || [];
    let listAllISINs = remainCompany.ISINs || [];
    for (const child of childrenIds) {
      const childAliases = rows.filter((company) => company._id === child);
      listAllAliases.push(childAliases[0].name);
      if (childAliases[0].aliases?.length > 0)
        listAllAliases = listAllAliases.concat(childAliases[0].aliases);
      if (childAliases[0].ISINs?.length > 0)
        listAllISINs = listAllISINs.concat(childAliases[0].ISINs);
    }

    setLoading(true);
    mergeCompanies({
      selectedCompany:parent,
      companiesToMerge:children,
      username: username,
    })
      .then((res) => {
        let filtered = rows.filter((obj) => {
          for (const selectId of selectionModel) {
            if (selectId === obj._id && selectId !== remainCompany._id)
              return false;
            if (remainCompany._id === obj._id) {
              obj.aliases = listAllAliases;
            }
          }
          return true;
        });
        setRows(filtered);
        alert("Companies merged");
      })
      .catch((error) => {
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally((_) => {
        setRemainOptions([]);
        setRemainCompany(undefined);
        setShowMergeModal(false);
        setLoading(false);
      });
  };

  /*ANCHOR PAGE CHANGE COMPANIES FUNCTION*/
  /**
   * Manages the visualization of the merge companie's modal.
   * @author Raferu
   */
  const openMergeModal = () => {
    let filtered = rows.filter((obj) => {
      for (let selection of selectionModel) {
        if (selection === obj._id) return true;
      }
      return false;
    });
    setRemainCompany(undefined);
    setRemainOptions(filtered);
    setShowMergeModal(true);
  };

  const updateTable = () => {
    let aux = rows.map((com) => {
      if (com._id === currentEditCompanyRef.current._id) {
        return currentEditCompanyRef.current; // i don´t return the company data inside the response because it returns the company before the updating
      } else return com;
    });
    setRows(aux);
  };

  const tableProps = {
    rows,
    columns: showLogsTable ? columnsLogs : columnsCompanies,
    showLogsTable,
    setRows,
    rowsCount,
    loadingTable,
    searchFunction,
    formikMainSearch,
    selectionModel,
    setSelectionModel,
  };

  useImperativeHandle(ref, () => ({
    openMergeModal,
  }));

  return (
    <>
      {loading && <SpinnerFull message={"Saving changes"} />}
      <EditTable {...tableProps} />
      <EditCompanyForm
        {...{
          setCurrentEditCompany,
          editCompany,
          updateTable,
          showModal,
          setShowModal,
          currentEditCompanyRef,
          currentEditCompany,
        }}
      />
      <BaseModal
        showModal={showMergeModal}
        setShowModal={setShowMergeModal}
        title="Merge Companies"
      >
        <BaseModal.Body>
          <p className="error-message-isin">{errorModalMessageIsin.msg}</p>
          {remainOptions.length < 2 || remainOptions.length > 5 ? (
            <>
              <p className="text-danger">
                {remainOptions.length < 2
                  ? "Please select at least two companies to merge."
                  : "You cannot merge more than 5 companies"}
              </p>
            </>
          ) : (
            <>
              <Box>
                <p>Are you sure you want to merge these companies?</p>
                <p>
                  <strong>Warning: This operation is irreversible</strong>
                </p>
                <Box sx={{ width: "100%" }}>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      className="w-100"
                    >
                      {remainCompany
                        ? remainCompany.name + " from " + remainCompany.country
                        : "None"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {remainOptions.map((option) => {
                        return (
                          <Dropdown.Item
                            key={`${option.name}-from-${option.country}`}
                            onClick={() => setRemainCompany(option)}
                          >
                            {option.name} from {option.country}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <FormHelperText>Select company that will stay</FormHelperText>
                </Box>
              </Box>
            </>
          )}
        </BaseModal.Body>
        {remainOptions.length < 2 || remainOptions.length > 5 ? null : (
          <BaseModal.Footer>
            <Button
              disabled={remainCompany === undefined}
              className="m-2"
              variant="success"
              onClick={handleMerge}
            >
              Continue
            </Button>
            <Button className="m-2" variant="danger" onClick={handleMergeModal}>
              Cancel
            </Button>
          </BaseModal.Footer>
        )}
      </BaseModal>
    </>
  );
};

export default forwardRef(CompaniesTable);
