import parse from 'html-react-parser';

export default function SubscribeMessage () {
    return <>
{parse(
`<div id="mc_embed_shell">
    <div id="mc_embed_signup">
        <form 
        action=${process.env.REACT_APP_MAILCHIMP_URL} 
        method="post" 
        id="mc-embedded-subscribe-form" 
        name="mc-embedded-subscribe-form" 
        class="validate" 
        target="_blank">
            
            <div id="mc_embed_signup_scroll">
                <h2>
                While clients have access to our entire dataset, the global public database is still 
                being developed. You can now view individual Voting Records for S&P 500 companies, 
                plus investors' aggregated voting data covering 40,000+ companies in the Graphs section. 
                Much more coming soon! <br/><br/> 
                Subscribe to our newsletter for updates:
                </h2>
                <div class="indicates-required">
                    <span class="asterisk">*</span> indicates required
                </div>
                <div class="mc-field-group">
                    <label for="mce-EMAIL">
                        Email Address <span class="asterisk">*</span>
                    </label>
                    <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="">
                </div>
                <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display: none;"></div>
                <div class="response" id="mce-success-response" style="display: none;"></div>
                </div>
                <div aria-hidden="true" style="position: absolute; left: -5000px;">
                    <input 
                    type="text" 
                    name="b_b97430325f2d4dd78f56ae125_748d732dcd" 
                    tabindex="-1" 
                    value="">
                </div>
                <div class="clear">
                    <input 
                    type="submit" 
                    name="subscribe" 
                    id="mc-embedded-subscribe" 
                    class="button" 
                    value="Subscribe">
                </div>
            </div>
        </form>
    </div>
    <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[7]='MMERGE7';ftypes[7]='dropdown';fnames[6]='MMERGE6';ftypes[6]='dropdown';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
</div>`
)}
</>
}